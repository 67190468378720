<template>
  <el-input
    type="text"
    id="autocomplete"
    autocomplete="on"
    v-model="address.formatted"
    placeholder="Enter a location"
  />
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      address: {
        place_id: '',
        address: '',
        zip: '',
        state: '',
        city: '',
        formatted: '',
      },
    };
  },
  watch: {
    value(newVal) {
      if (newVal !== this.address) {
        this.address = newVal;
      }
    },
  },

  mounted() {
    this.initAutocomplete();
  },
  methods: {
    initAutocomplete() {
      const input = document.getElementById('autocomplete');
      this.$gmapApiPromiseLazy().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(input, {
          fields: ['address_components', 'place_id', 'formatted_address'],
          componentRestrictions: { country: ['us', 'ca'] },
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          const address = place.address_components.reduce((memo, { types, long_name, short_name }) => {
            if (types.includes('street_number')) {
              memo.address = `${long_name} ${memo.address}`;
            } else if (types.includes('route')) {
              memo.address += long_name;
            } else if (types.includes('locality')) {
              memo.city = long_name;
            } else if (types.includes('administrative_area_level_2')) {
              memo.city = long_name;
            } else if (types.includes('administrative_area_level_1')) {
              // Tekmetric only receives shortcodes for state AZ, CA, etc.
              memo.state = short_name;
            } else if (types.includes('postal_code')) {
              memo.zip = long_name;
            }

            return memo;
          }, {
            address: '',
            city: '',
            state: '',
            zip: '',
          });

          this.address = {
            ...address,
            formatted: place.formatted_address || '',
            place_id: place.place_id,
          };

          this.$emit('input', this.address);
        });
      });
    },
  },
};
</script>
