<template>
  <div class="desktop-footer">
    <div class="text-body-5">
      <router-link :to="'/privacy?shop='+ (this.accountSlug||this.$route.query.shop)"

                   target="_blank">
        Privacy Policy
      </router-link>
      |
      <router-link :to="'/terms?shop='+ (this.accountSlug||this.$route.query.shop)" target="_blank">
        Terms & Conditions
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopFooter",
  props: {
    accountSlug: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
</style>