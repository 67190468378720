<template>
  <div class="service-step-wrapper manually-describe-service-step-wrapper">
    <div class="service-step-card">
      <div class="text-header-4 mb-12px">Additional notes (optional)</div>

      <div>
        <div class="input-with-displayed-text-length-wrapper">
          <el-input
            type="textarea"
            rows="4"
            placeholder="I hear a ticking noise coming from my engine bay..."
            v-model="description"
            :maxlength="descriptionMaxLength"
          />
          <div class="input-text-length">
            {{ description.length }} / {{ descriptionMaxLength }}
          </div>
        </div>
        <div class="add-attachments-box" @click="attachFiles">
          <div class="add-attachments-box-body" v-if="!selectedFile">
            <div class="img-wrapper">
              <img
                src="/img/add-attachments-img.png"
                alt="add-attachments-img"
              />
            </div>

            <div class="text-body-4">Optional photos</div>
          </div>
          <div v-else class="imageVideoPreviewBox">
            <img
              v-show="!selectedFile.type.includes('video')"
              class="image-preview"
              :src="selectedFileImagePreview"
              alt="preview"
            />
            <video
              v-show="selectedFile.type.includes('video')"
              class="video-preview"
            ></video>
            <div class="imageVideoPreviewBoxOverlay"></div>
            <div class="removeAttachmentIcon" @click="selectedFile = null">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                width="20"
                height="20"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        </div>

        <input
          @change="onFileChange"
          ref="attachmentsInput"
          class="hidden"
          type="file"
        />
      </div>
    </div>

    <div class="sticked-to-bottom-button-wrapper">
      <div class="content-container">
        <button
          @click="submitInfo"
          :disabled="!isFormOptional && !description"
          class="primary-btn full-width"
        >
          <span>Next</span>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.50195 6L15.502 12L9.50195 18"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManuallyDescribeServiceStep",
  props: {
    isFormOptional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      description: "",
      selectedFileImagePreview: null,
      selectedFile: null,
      descriptionMaxLength: 300,
    };
  },
  methods: {
    attachFiles() {
      if (this.selectedFile) return;
      this.$refs.attachmentsInput.click();
    },
    getVideoThumbnail(file) {
      const video = document.querySelector("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnail = canvas.toDataURL("image/png");
      };
      video.src = URL.createObjectURL(file);
    },
    async onFileChange(ev) {
      if (!ev.target.files.length) return;

      this.selectedFile = ev.target.files[0];
      this.selectedFileImagePreview = URL.createObjectURL(this.selectedFile);

      if (this.selectedFile.type.includes("video")) {
        await new Promise((resolve) => setTimeout(resolve, 50));
        this.getVideoThumbnail(this.selectedFile);
      }
    },
    submitInfo() {
      const { description, selectedFile } = this.$data;
      this.$posthog.capture("serviceSelection_click_diagnosticProblemManualDescriptionNextButton")
      this.$emit("info-submitted", { description, selectedFile });
    },
  },
};
</script>

<style lang="scss">
.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageVideoPreviewBox {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  overflow: hidden;
}

.imageVideoPreviewBoxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(55, 125, 255);
  opacity: 0.1;
  z-index: 1;
}

.removeAttachmentIcon {
  position: absolute;
  width: 32px;
  height: 32px;
  top: -1px;
  right: -1px;
  background: rgb(55, 125, 255);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  color: #fff;
}
</style>
