import apiClient from '@/client';

export default {
  post: {
    contact: (account, name, phone, message, customer_message_source = 'bookingTool') => {
      return apiClient.post(
        `/public/accounts/${account}/customers/contact`,
        {
          customer_name: name,
          customer_phone: phone,
          customer_message: message,
          customer_message_source
        }
      );
    }
  }
};
