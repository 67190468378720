import apiClient from '@/client';

export default {
  get: {
    index: (shop, params = {}) => {
      return apiClient.get(`/public/accounts/${shop}/services`, { params });
    },
    shopInfo: (slug) => {
      return apiClient.get(`/public/accounts/${slug}`);
    },
    shopExtraInfo: (slug) => {
      return apiClient.get(`/public/accounts/${slug}/extra`);
    },
    availabilityData: (slug, appointmentType, services, daysAhead = 60, startDate = undefined) => {
      // @TODO - temp fix for this clients, because of the complexity of the change needed to be done in the backend
      if (slug === 'repair-dynamics-1797078764') {
        daysAhead = 100;
      }
      return apiClient.get(`/public/accounts/${slug}/availability`, {
        params: {
          days_ahead: daysAhead,
          appointment_type: appointmentType,
          services,
          start_date: startDate,
        },
      });
    },
  },
};
