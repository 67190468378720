<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 435.73 435.73"
    style="enable-background: new 0 0 435.73 435.73"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M426.73,80.164h-20.251v-3.115c0-4.971-4.029-9-9-9h-46.498c-4.971,0-9,4.029-9,9v3.115h-73.197L249.726,31.63l1.694-0.665
	c4.627-1.817,6.904-7.041,5.087-11.667c-1.816-4.626-7.043-6.901-11.667-5.086L139.182,55.713
	c-4.627,1.817-6.904,7.041-5.087,11.667c1.393,3.546,4.787,5.712,8.379,5.712c1.095,0,2.208-0.201,3.288-0.625l1.691-0.665
	l3.737,9.515C66.2,91.163,0,163.568,0,251.154c0,94.284,76.706,170.99,170.99,170.99s170.99-76.706,170.99-170.99
	c0-22.533-4.254-44.242-12.664-64.72h12.665v3.109c0,4.971,4.029,9,9,9h46.498c4.971,0,9-4.029,9-9v-3.109h20.251
	c4.971,0,9-4.029,9-9v-88.27C435.73,84.193,431.701,80.164,426.73,80.164z M164.207,65.221l68.767-27.01l16.474,41.953H170.99
	c-0.304,0-0.606,0.01-0.91,0.012L164.207,65.221z M170.99,168.43L170.99,168.43C170.991,168.43,170.991,168.43,170.99,168.43
	c-45.614,0-82.724,37.11-82.724,82.724s37.109,82.724,82.724,82.724s82.724-37.11,82.724-82.724
	c0-26.188-12.246-49.556-31.297-64.724h87.229c9.508,20.29,14.334,42.035,14.334,64.725c0,84.359-68.631,152.99-152.99,152.99
	S18,335.513,18,251.154c0-84.359,68.631-152.99,152.99-152.99h170.991v70.27L170.99,168.43z M190.211,251.154
	c0,10.598-8.622,19.22-19.221,19.22c-10.598,0-19.22-8.622-19.22-19.22s8.622-19.221,19.22-19.221
	C181.589,231.933,190.211,240.556,190.211,251.154z M179.99,215.04v-27.97c5.315,0.743,10.423,2.133,15.246,4.09l-13.986,24.223
	C180.832,215.263,180.414,215.145,179.99,215.04z M161.99,215.04c-0.424,0.106-0.843,0.223-1.26,0.343l-13.985-24.223
	c4.823-1.957,9.931-3.347,15.246-4.09V215.04z M131.171,200.185l13.981,24.216c-0.311,0.3-0.615,0.605-0.916,0.916l-24.216-13.981
	C123.273,207.182,127.018,203.437,131.171,200.185z M110.996,226.909l24.224,13.986c-0.12,0.417-0.237,0.836-0.343,1.259h-27.971
	C107.649,236.839,109.04,231.732,110.996,226.909z M134.877,260.154c0.106,0.423,0.223,0.841,0.343,1.259l-24.224,13.986
	c-1.956-4.823-3.346-9.93-4.09-15.245H134.877z M144.237,276.992c0.3,0.311,0.605,0.615,0.915,0.915l-13.981,24.216
	c-4.153-3.252-7.898-6.997-11.15-11.15L144.237,276.992z M160.73,286.924c0.418,0.12,0.836,0.238,1.26,0.343v27.97
	c-5.315-0.743-10.423-2.133-15.245-4.09L160.73,286.924z M179.99,287.268c0.423-0.105,0.842-0.223,1.259-0.343l13.986,24.223
	c-4.823,1.957-9.93,3.347-15.245,4.09V287.268z M210.809,302.123l-13.981-24.215c0.31-0.3,0.615-0.605,0.916-0.916l24.216,13.981
	C218.707,295.127,214.962,298.871,210.809,302.123z M230.984,275.4l-24.223-13.985c0.12-0.418,0.238-0.836,0.343-1.26h27.97
	C234.331,265.469,232.94,270.577,230.984,275.4z M207.104,242.154c-0.105-0.424-0.223-0.842-0.343-1.26l24.223-13.985
	c1.956,4.823,3.347,9.93,4.09,15.245H207.104z M197.744,225.316c-0.3-0.311-0.605-0.616-0.916-0.916l13.981-24.215
	c4.153,3.252,7.898,6.997,11.15,11.15L197.744,225.316z M388.479,180.543h-28.498V86.049h28.498V180.543z M417.73,168.434h-11.251
	v-70.27h11.251V168.434z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>