<template>
  <div>
    <locations-loading v-if="loading" />

    <transition :name="mapViewTransition">
      <locations-map
        v-if="showMapView && !loading"
        :show-ask-for-permissions-card="showAskForLocationPermissionsCard"
        :client-location="clientLocation"
        :locations="geolocationFilledLocations"
        @location-selected="handleLocationSelected"
        @close-ask-for-permissions="$emit('close-ask-for-permissions')"
      />
    </transition>

    <transition name="slide-up">
      <locations-list
        v-if="showListView && !loading"
        :show-ask-for-permissions-card="showAskForLocationPermissionsCard"
        :loading="loading"
        :locations="locations"
        @location-selected="handleLocationSelected"
        @close-ask-for-permissions="$emit('close-ask-for-permissions')"
      />
    </transition>
  </div>
</template>

<script>
import moment from "moment";

import LocationsLoading from "./LocationsLoading";
import LocationsList from "./LocationsList";
import LocationsMap from "./LocationsMap";

import CompanyService from "@/services/CompanyService";
import CustomCollapseTransition from "./CustomCollapseTransition";

const { VUE_APP_DEV_SHOP } = process.env;

export default {
  name: "SelectLocationStep",
  components: {
    LocationsLoading,
    LocationsList,
    LocationsMap,
    CustomCollapseTransition,
  },
  props: {
    showListView: {
      type: Boolean,
      default: false,
    },
    showMapView: {
      type: Boolean,
      default: false,
    },
    mapViewTransition: {
      type: String,
      required: true,
    },
    showAskForLocationPermissionsCard: {
      type: Boolean,
      default: false,
    },
    clientLocation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      locations: [
        { slug: "location-1" },
        { slug: "location-2" },
        { slug: "location-3" },
      ],
    };
  },
  async mounted() {
    await this.fetchLocations();
    this.removeWizardTabContentPadding();
  },
  computed: {
    companySlug() {
      return this.$route.query.shop || VUE_APP_DEV_SHOP || null;
    },
    preselectAccountSlug() {
      return this.$route.query.preselect_account || null;
    },
    geolocationFilledLocations() {
      return this.locations.filter((location) => location.lat && location.lng);
    },
  },
  watch: {
    clientLocation() {
      this.locations = this.getLocationsSortedByDistance();
    },
  },
  methods: {
    removeWizardTabContentPadding() {
      const wizardTabContent = document
        .querySelector(".select-location-step-wrapper.is-map")
        ?.closest(".wizard-tab-content");
      if (!wizardTabContent) return;
      wizardTabContent.style.paddingBottom = "0";
    },
    calculateHaversineDistance(lat1, lon1, lat2, lon2) {
      const R = 3958.8; // Radius of the Earth in miles, use 6371 for km

      const rlat1 = lat1 * (Math.PI / 180); // Convert degrees to radians
      const rlat2 = lat2 * (Math.PI / 180); // Convert degrees to radians
      const difflat = rlat2 - rlat1; // Radian difference (latitudes)
      const difflon = (lon2 - lon1) * (Math.PI / 180); // Radian difference (longitudes)

      const d =
        2 *
        R *
        Math.asin(
          Math.sqrt(
            Math.sin(difflat / 2) * Math.sin(difflat / 2) +
              Math.cos(rlat1) *
                Math.cos(rlat2) *
                Math.sin(difflon / 2) *
                Math.sin(difflon / 2)
          )
        );

      return d;
    },
    getLocationsSortedByDistance() {
      if (!this.clientLocation) return this.locations;

      return [...this.locations]
        .map((location) => {
          return {
            ...location,
            harvesine_distance: parseFloat(
              this.calculateHaversineDistance(
                this.clientLocation.lat,
                this.clientLocation.lng,
                location.lat,
                location.lng
              ).toFixed(2)
            ),
          };
        })
        .sort((a, b) => a.harvesine_distance - b.harvesine_distance);
    },
    getShopAvailabilityFormattedLocations(locations) {
      const currentDay = moment().format("dddd");

      return locations.map((location) => {
        const currentDaySettings = location?.work_hours_settings?.value.find(
          (day) => day.day === currentDay
        );

        if (!currentDaySettings) {
          return {
            ...location,
            shop_opened_text: "Closed today",
          };
        }

        const { start_hour, end_hour } = currentDaySettings;
        const formattedStartHour = moment(start_hour, "HH:mm").format(
          "hh:mm a"
        );
        const formattedEndHour = moment(end_hour, "HH:mm").format("hh:mm a");

        const currentTime = moment().format("HH:mm");

        if (currentTime >= start_hour && currentTime <= end_hour) {
          return {
            ...location,
            shop_opened_text: `Closes today at ${formattedEndHour}`,
          };
        }

        if (currentTime > end_hour) {
          return {
            ...location,
            shop_opened_text: `Reopens tomorrow at ${formattedStartHour}`,
          };
        }

        if (currentTime < start_hour) {
          return {
            ...location,
            shop_opened_text: `Opens today at ${formattedStartHour}`,
          };
        }

        return {
          ...location,
          shop_opened_text: "Closed today",
        };
      });
    },
    async fetchLocations() {
      const {
        data: { data: companyLocations },
      } = await CompanyService.get.companyAccounts(this.companySlug);

      if (companyLocations.length === 1) {
        this.handleLocationSelected(companyLocations[0], false);
        return;
      }

      const preselectedAccount = companyLocations.find(
        (location) => location.slug === this.preselectAccountSlug
      );

      if (preselectedAccount) {
        this.handleLocationSelected(preselectedAccount, false);
        return;
      }

      const shopAvailabilityFilledLocations =
        this.getShopAvailabilityFormattedLocations(companyLocations);

      shopAvailabilityFilledLocations.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      this.locations = shopAvailabilityFilledLocations;
      this.loading = false;
    },
    handleLocationSelected(location, isMSO = true) {
      this.$emit("location-selected", location, isMSO);
    },
  },
};
</script>
