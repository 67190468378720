<template>
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.0715 11.9426C35.5261 11.9426 37.5159 9.95275 37.5159 7.49816C37.5159 5.04356 35.5261 3.05371 33.0715 3.05371C30.6169 3.05371 28.627 5.04356 28.627 7.49816C28.627 9.95275 30.6169 11.9426 33.0715 11.9426Z"
      fill="#2D3139"
    />
    <path
      d="M6.8174 11.9426C6.8174 10.4085 8.06106 9.16482 9.59518 9.16482H22.867C23.7875 9.16482 24.5336 8.41863 24.5336 7.49816C24.5336 6.57768 23.7875 5.83149 22.867 5.83149H9.59518C6.22011 5.83149 3.48407 8.56753 3.48407 11.9426V22.7588C3.48407 25.3672 5.11724 27.5925 7.41734 28.4701V32.9301C7.41734 35.2421 10.243 36.3657 11.8306 34.685L17.3237 28.8699H28.6271C32.0022 28.8699 34.7382 26.1339 34.7382 22.7588V17.3357C34.7382 16.4152 33.992 15.669 33.0716 15.669C32.1511 15.669 31.4049 16.4152 31.4049 17.3357V22.7588C31.4049 24.2929 30.1613 25.5366 28.6271 25.5366H16.6053C16.1468 25.5366 15.7086 25.7255 15.3938 26.0588L10.7507 30.9741V27.1742C10.7507 26.3271 10.1152 25.6147 9.27362 25.5183C7.89173 25.3601 6.8174 24.1837 6.8174 22.7588V11.9426Z"
      fill="#2D3139"
    />
  </svg>
</template>

<script>
export default {
  name: "UnreadMessageIcon",
};
</script>

<style>
</style>