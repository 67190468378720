<template>
  <div class="fixed-shop-location-map">
    <GmapMap :zoom="mapZoom" :center="center" :options="gmapOptions">
      <GmapMarker
        :key="selectedAccountLocation.lat + selectedAccountLocation.lng"
        :position="selectedAccountLocation"
        :icon="'/assets/pin-selected.svg'"
        :clickable="false"
        :draggable="false"
      />
    </GmapMap>
  </div>
</template>
<script>
export default {
  name: "FixedShopMap",
  data() {
    return {
      gmapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        draggable: false,
        zoomControl: false,
        keyboardShortcuts: false,
        styles: [
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
      center: { lat: 36.9316608, lng: -104.7199744 },
      mapZoom: 14,
      currentTransition: "fade-faster",
    };
  },
  props: {
    selectedAccountLocation: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.center = {
      lat: this.selectedAccountLocation.lat,
      lng: this.selectedAccountLocation.lng,
    };
  },
};
</script>
<style lang="scss" scoped>
.fixed-shop-location-map {
  width: 92%;
  margin: 0 auto;
  > .vue-map-container {
    margin-top: 4px;
    height: 160px;
    > ::v-deep .vue-map {
      border-radius: 8px;
    }
  }
}
</style>
