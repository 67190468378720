<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_871_9039)">
      <path
        d="M2.85742 16.0003C2.85742 17.7262 3.19737 19.4353 3.85786 21.0298C4.51835 22.6244 5.48645 24.0733 6.70688 25.2937C7.9273 26.5141 9.37616 27.4822 10.9707 28.1427C12.5653 28.8032 14.2743 29.1431 16.0003 29.1431C17.7262 29.1431 19.4353 28.8032 21.0298 28.1427C22.6244 27.4822 24.0733 26.5141 25.2937 25.2937C26.5141 24.0733 27.4822 22.6244 28.1427 21.0298C28.8032 19.4353 29.1431 17.7262 29.1431 16.0003C29.1431 14.2743 28.8032 12.5653 28.1427 10.9707C27.4822 9.37616 26.5141 7.9273 25.2937 6.70688C24.0733 5.48645 22.6244 4.51835 21.0298 3.85786C19.4353 3.19737 17.7262 2.85742 16.0003 2.85742C14.2743 2.85742 12.5653 3.19737 10.9707 3.85786C9.37616 4.51835 7.9273 5.48645 6.70688 6.70688C5.48645 7.9273 4.51835 9.37616 3.85786 10.9707C3.19737 12.5653 2.85742 14.2743 2.85742 16.0003V16.0003Z"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.42871 16.0001C7.42871 18.2734 8.33177 20.4536 9.93922 22.0611C11.5467 23.6685 13.7269 24.5716 16.0001 24.5716C18.2734 24.5716 20.4536 23.6685 22.0611 22.0611C23.6685 20.4536 24.5716 18.2734 24.5716 16.0001C24.5716 13.7269 23.6685 11.5467 22.0611 9.93922C20.4536 8.33177 18.2734 7.42871 16.0001 7.42871C13.7269 7.42871 11.5467 8.33177 9.93922 9.93922C8.33177 11.5467 7.42871 13.7269 7.42871 16.0001V16.0001Z"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5713 15.9999C12.5713 16.9092 12.9325 17.7812 13.5755 18.4242C14.2185 19.0672 15.0905 19.4284 15.9999 19.4284C16.9092 19.4284 17.7812 19.0672 18.4242 18.4242C19.0672 17.7812 19.4284 16.9092 19.4284 15.9999C19.4284 15.0905 19.0672 14.2185 18.4242 13.5755C17.7812 12.9325 16.9092 12.5713 15.9999 12.5713C15.0905 12.5713 14.2185 12.9325 13.5755 13.5755C12.9325 14.2185 12.5713 15.0905 12.5713 15.9999Z"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2861 13.0293V7.59961"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.7139 7.59961V13.0293"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3076 13.4642L23.1705 11.3008"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.4427 14.5059L19.3467 16.7447"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.2871 16.9795L22.9888 20.9635"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5094 23.2902L16.7939 19.3359"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6929 13.4642L8.83008 11.3008"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.55859 14.5059L12.6535 16.7447"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7134 16.9795L9.01172 20.9635"
        stroke="currentColor"
        stroke-width="1.22449"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4902 23.2902L15.2057 19.3359"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_871_9039">
        <rect width="28" height="28" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>