<template>
  <div class="select-location-step-wrapper is-map">
    <div class="map-wrapper">
      <gmap-map
        ref="gmap"
        style="width: 100%; height: 100%"
        :zoom="mapZoom"
        :center="center"
        :options="gmapOptions"
      >
        <gmap-marker
          @click="handleGmapMarkerClick(marker)"
          :key="index"
          :icon="getMarkerIcon(marker)"
          :z-index="getMarkerZIndex(marker)"
          v-for="(marker, index) in locationMarkers"
          :position="marker.position"
        />
        <gmap-marker
          v-if="clientLocation"
          :key="clientLocation.lat + clientLocation.lng"
          :icon="'/assets/client-location-pin.svg'"
          :position="clientLocation"
        />
      </gmap-map>
    </div>

    <transition name="fade-fastest">
      <div v-if="showAskForPermissionsCard">
        <div class="map-location-cards-wrapper ask-for-permissions-wrapper">
          <ask-for-permissions-card
            :full-height="true"
            @close="$emit('close-ask-for-permissions')"
          />
        </div>
      </div>
    </transition>

    <transition :name="currentTransition" mode="out-in">
      <div :key="displayedLocationIdx" class="map-location-cards-wrapper">
        <div
          v-if="displayedLocationIdx !== 0"
          class="change-location-icon-wrapper"
          @click="mapLocationGoBack"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7122 20.6638C15.1223 21.0738 15.7871 21.0738 16.1972 20.6638C16.6072 20.2537 16.6072 19.5889 16.1972 19.1788L9.0038 11.9855L16.1972 4.7921C16.6072 4.38205 16.6072 3.71722 16.1972 3.30717C15.7871 2.89712 15.1223 2.89712 14.7122 3.30717L6.77715 11.2423C6.72589 11.2935 6.6803 11.3495 6.64186 11.4077C6.37277 11.8153 6.41762 12.3691 6.77641 12.7279L14.7122 20.6638Z"
              fill="#2D3139"
            />
          </svg>
        </div>

        <map-company-location-card
          :location="locations[displayedLocationIdx]"
          @location-selected="
            $emit('location-selected', locations[displayedLocationIdx])
          "
        />

        <div
          v-if="displayedLocationIdx !== locations.length - 1"
          class="change-location-icon-wrapper"
          @click="mapLocationGoNext"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.29246 3.32133C8.88241 2.91128 8.21759 2.91128 7.80754 3.32133C7.39749 3.73138 7.39749 4.39621 7.80754 4.80626L15.0009 11.9996L7.80754 19.193C7.39749 19.6031 7.39749 20.2679 7.80754 20.6779C8.21759 21.088 8.88241 21.088 9.29246 20.6779L17.2276 12.7428C17.2273 12.7431 17.2278 12.7426 17.2276 12.7428C17.2788 12.6916 17.3244 12.6356 17.3628 12.5774C17.6319 12.1698 17.5871 11.616 17.2283 11.2572C17.2281 11.2569 17.2285 11.2574 17.2283 11.2572L9.29246 3.32133Z"
              fill="#2D3139"
            />
          </svg>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AskForPermissionsCard from "./AskForPermissionsCard";
import MapCompanyLocationCard from "./MapCompanyLocationCard";

export default {
  name: "LocationsMap",
  components: {
    AskForPermissionsCard,
    MapCompanyLocationCard,
  },
  props: {
    clientLocation: {
      type: Object,
      required: false,
    },
    locations: {
      type: Array,
      required: true,
    },
    showAskForPermissionsCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gmapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: true,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#323a4b",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#e3e5e8",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.natural.landcover",
            elementType: "all",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "landscape.natural.landcover",
            elementType: "geometry",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "landscape.natural.terrain",
            elementType: "all",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#ff0000",
              },
            ],
          },
          {
            featureType: "landscape.natural.terrain",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#84f0ca",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#84f0ca",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.business",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.government",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#84f0ca",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#ff0000",
              },
            ],
          },
          {
            featureType: "poi.place_of_worship",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.school",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.sports_complex",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#84f0ca",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#323a4b",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
              {
                saturation: "100",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry.stroke",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry.stroke",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [
              {
                saturation: "100",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "labels.icon",
            stylers: [
              {
                saturation: "100",
              },
            ],
          },
          {
            featureType: "transit.station.airport",
            elementType: "all",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.airport",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f1f2f4",
              },
            ],
          },
          {
            featureType: "transit.station.airport",
            elementType: "geometry.stroke",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.station.airport",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.station.bus",
            elementType: "labels.icon",
            stylers: [
              {
                saturation: "91",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#99bdff",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
        ],
      },

      displayedLocationIdx: 0,
      mapZoom: 10,
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },

      currentTransition: "fade-faster",
    };
  },
  mounted() {
    this.center = {
      lat: this.locations[0].lat,
      lng: this.locations[0].lng,
    };

    // set an interval to check if google variable is not undefined
    // if it is not undefined, clear the interval and call the function
    // to fit bounds to markers
    const interval = setInterval(() => {
      if (typeof google !== "undefined") {
        clearInterval(interval);
        const markers = this.clientLocation
          ? [...this.locationMarkers, { position: this.clientLocation }]
          : this.locationMarkers;
        this.fitBoundsToMarkers(markers);
      }
    }, 50);
  },
  watch: {
    clientLocation() {
      if (!this.clientLocation) return;

      this.fitBoundsToMarkers([
        this.locationMarkers[0],
        { position: this.clientLocation },
      ]);
    },
  },
  computed: {
    locationMarkers() {
      return this.locations.map((location) => {
        return {
          position: {
            lat: location.lat,
            lng: location.lng,
          },
        };
      });
    },
  },
  methods: {
    isMarkerSelected(marker) {
      const currentDisplayedLocation =
        this.locations[this.displayedLocationIdx];

      return (
        currentDisplayedLocation.lat === marker.position.lat &&
        currentDisplayedLocation.lng === marker.position.lng
      );
    },
    getMarkerIcon(marker) {
      return this.isMarkerSelected(marker)
        ? "/assets/pin-selected.svg"
        : "/assets/pin-normal.svg";
    },
    getMarkerZIndex(marker) {
      return this.isMarkerSelected(marker) ? 1000 : 1;
    },
    fitBoundsToMarkers(markers) {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach((marker) => {
        bounds.extend(marker.position);
      });

      this.$refs.gmap.fitBounds(bounds);
    },
    mapFitDisplayedLocationBounds() {
      const markers = this.clientLocation
        ? [
            ...this.locationMarkers.slice(0, this.displayedLocationIdx + 1),
            { position: this.clientLocation },
          ]
        : this.locationMarkers.slice(0, this.displayedLocationIdx + 1);

      this.fitBoundsToMarkers(markers);
    },
    mapLocationGoBack() {
      this.currentTransition = "slide-back";

      setTimeout(() => {
        this.displayedLocationIdx -= 1;

        this.mapFitDisplayedLocationBounds();
      }, 50);
    },
    mapLocationGoNext() {
      this.currentTransition = "slide-next";

      setTimeout(() => {
        this.displayedLocationIdx += 1;

        this.mapFitDisplayedLocationBounds();
      }, 50);
    },
    handleGmapMarkerClick(marker) {
      const { lat, lng } = marker.position;

      const markerIdx = this.locationMarkers.findIndex(
        (locationMarker) =>
          locationMarker.position.lat === lat &&
          locationMarker.position.lng === lng
      );

      if (markerIdx === this.displayedLocationIdx) return;

      this.currentTransition =
        markerIdx > this.displayedLocationIdx ? "slide-next" : "slide-back";

      setTimeout(() => {
        this.displayedLocationIdx = markerIdx;

        this.mapFitDisplayedLocationBounds();
      }, 50);
    },
  },
};
</script>

<style>
</style>