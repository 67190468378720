<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2.5H18C18.8284 2.5 19.5 3.17157 19.5 4V18C19.5 18.8284 18.8284 19.5 18 19.5H4C3.17157 19.5 2.5 18.8284 2.5 18V4C2.5 3.17157 3.17157 2.5 4 2.5ZM11.5465 14.0455V15H17.2908V13.8707H13.3619V13.8068L15.0962 11.9915C15.6275 11.4517 16.0423 10.9929 16.3406 10.6151C16.6389 10.2344 16.8477 9.88921 16.967 9.57955C17.0891 9.26989 17.1502 8.95313 17.1502 8.62926C17.1502 8.16051 17.0323 7.74006 16.7965 7.3679C16.5607 6.9929 16.2326 6.69745 15.8121 6.48154C15.3945 6.26279 14.9116 6.15341 14.3633 6.15341C13.8207 6.15341 13.3363 6.26421 12.9102 6.4858C12.484 6.70455 12.1488 7.01279 11.9045 7.41051C11.6602 7.80824 11.538 8.27273 11.538 8.80398H12.7951C12.7951 8.49148 12.8576 8.21875 12.9826 7.9858C13.1076 7.75284 13.2866 7.57245 13.5195 7.4446C13.7525 7.31676 14.0252 7.25284 14.3377 7.25284C14.6332 7.25284 14.8988 7.31108 15.1346 7.42756C15.3704 7.5412 15.5565 7.70455 15.6928 7.91762C15.8292 8.12784 15.8974 8.37784 15.8974 8.66762C15.8974 8.9233 15.8462 9.16478 15.744 9.39205C15.6417 9.61932 15.4869 9.85938 15.2795 10.1122C15.0749 10.3622 14.815 10.6534 14.4996 10.9858L11.5465 14.0455ZM4.8924 15V6.27273H6.20916V13.8665H10.1637V15H4.8924Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.66667 0C1.64162 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.64162 22 3.66667 22H18.3333C20.3584 22 22 20.3584 22 18.3333V3.66667C22 1.64162 20.3584 0 18.3333 0H3.66667ZM2 4C2 2.89543 2.89543 2 4 2H18C19.1046 2 20 2.89543 20 4V18C20 19.1046 19.1046 20 18 20H4C2.89543 20 2 19.1046 2 18V4Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>