<template>
  <div class="wizard-custom-heading">
    <div class="heading-left-wrapper">
      <div v-if="!showExitSurvey">
        <a href="#" @click.prevent="handleBackBtnClicked" class="heading-btn" v-if="showPrevBtn">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0342 15.4981C11.3417 15.8056 11.8403 15.8056 12.1478 15.4981C12.4554 15.1906 12.4554 14.6919 12.1478 14.3844L6.75282 8.98938L12.1478 3.59435C12.4554 3.28681 12.4554 2.78819 12.1478 2.48065C11.8403 2.17312 11.3417 2.17312 11.0342 2.48065L5.08283 8.43198V8.43198C5.04439 8.47042 5.0102 8.5124 4.98137 8.55607C4.77954 8.86174 4.81318 9.27713 5.08228 9.54622V9.54622L11.0342 15.4981Z"
              fill="#444A55"
            />
          </svg>
        </a>
      </div>
      <div v-if="!showExitSurvey">
        <div v-if="!isLastStep" class="text-header-3">{{ title }}</div>
        <div v-if="isLastStep" class="text-header-3">See you soon, {{ customerName }}!</div>
        <div v-if="subtitle" class="text-body-5">{{ subtitle }}</div>
      </div>
      <div v-else>
        <div class="text-header-3">Didn’t find what you needed?</div>
      </div>
    </div>

    <div v-if="!showExitSurvey">
      <div class="heading-btns-wrapper" v-if="!isLastStep">
        <div v-if="activeIndex === 0 || !activeIndex" class="switch-location-views-wrapper">
          <button
            class="switch-location-btn"
            :class="{ active: locationsShowMapView }"
            @click.prevent="$emit('show-locations-map-view')"
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.89815 5.14512C7.44652 5.14512 6.26974 6.3219 6.26974 7.77353C6.26974 9.22516 7.44652 10.4019 8.89815 10.4019C10.3498 10.4019 11.5266 9.22516 11.5266 7.77353C11.5266 6.3219 10.3498 5.14512 8.89815 5.14512ZM7.84475 7.77353C7.84475 7.19175 8.31637 6.72012 8.89815 6.72012C9.47994 6.72012 9.95156 7.19175 9.95156 7.77353C9.95156 8.35531 9.47994 8.82694 8.89815 8.82694C8.31637 8.82694 7.84475 8.35531 7.84475 7.77353Z"
                fill="#2D3139"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.46039 16.405C8.46061 16.4051 8.4608 16.4052 8.89763 15.75L8.46039 16.405C8.72492 16.5813 9.06994 16.5816 9.33446 16.4052L8.89763 15.75C9.33446 16.4052 9.33424 16.4054 9.33446 16.4052L9.33685 16.4036L9.34125 16.4007L9.35594 16.3908L9.40803 16.355C9.4525 16.3242 9.51607 16.2796 9.59601 16.2218C9.7558 16.1064 9.98147 15.9384 10.2511 15.7236C10.7891 15.2952 11.5086 14.676 12.2307 13.9138C13.6427 12.4233 15.2079 10.237 15.2079 7.77273C15.2079 6.09915 14.543 4.49412 13.3596 3.31072C12.1762 2.12733 10.5712 1.4625 8.89763 1.4625C7.22405 1.4625 5.61902 2.12733 4.43563 3.31072C3.25223 4.49412 2.5874 6.09915 2.5874 7.77273C2.5874 10.237 4.15257 12.4233 5.56458 13.9138C6.28669 14.676 7.00612 15.2952 7.54415 15.7236C7.81379 15.9384 8.03946 16.1064 8.19925 16.2218C8.27919 16.2796 8.34276 16.3242 8.38723 16.355L8.43932 16.3908L8.45401 16.4007L8.45841 16.4036L8.46039 16.405ZM5.54932 4.42442C6.43735 3.53639 7.64177 3.0375 8.89763 3.0375C10.1535 3.0375 11.3579 3.53639 12.2459 4.42442C13.134 5.31244 13.6329 6.51687 13.6329 7.77273C13.6329 9.60393 12.4367 11.4062 11.0873 12.8306C10.4287 13.5257 9.76749 14.0954 9.27 14.4916C9.13145 14.6019 9.00609 14.6984 8.89763 14.7799C8.78917 14.6984 8.66381 14.6019 8.52526 14.4916C8.02777 14.0954 7.36653 13.5257 6.70795 12.8306C5.3586 11.4062 4.1624 9.60393 4.1624 7.77273C4.1624 6.51687 4.66129 5.31244 5.54932 4.42442Z"
                fill="#2D3139"
              />
            </svg>
          </button>

          <button
            class="switch-location-btn"
            :class="{ active: locationsShowListView }"
            @click.prevent="$emit('show-locations-list-view')"
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.39893 3.25546C2.12278 3.25546 1.89893 3.47932 1.89893 3.75546V4.75113C1.89893 5.02727 2.12278 5.25113 2.39893 5.25113H3.39459C3.67073 5.25113 3.89459 5.02727 3.89459 4.75113V3.75546C3.89459 3.47932 3.67073 3.25546 3.39459 3.25546H2.39893ZM2.39893 8.00217C2.12278 8.00217 1.89893 8.22602 1.89893 8.50217V9.49783C1.89893 9.77398 2.12278 9.99783 2.39893 9.99783H3.39459C3.67073 9.99783 3.89459 9.77398 3.89459 9.49783V8.50217C3.89459 8.22602 3.67073 8.00217 3.39459 8.00217H2.39893ZM1.89893 13.2488C1.89893 12.9727 2.12278 12.7488 2.39893 12.7488H3.39459C3.67073 12.7488 3.89459 12.9727 3.89459 13.2488V14.2445C3.89459 14.5206 3.67073 14.7445 3.39459 14.7445H2.39893C2.12278 14.7445 1.89893 14.5206 1.89893 14.2445V13.2488ZM6.10107 3.5033C5.68686 3.5033 5.35107 3.83908 5.35107 4.2533C5.35107 4.66751 5.68686 5.0033 6.10107 5.0033H15.1721C15.5863 5.0033 15.9221 4.66751 15.9221 4.2533C15.9221 3.83908 15.5863 3.5033 15.1721 3.5033H6.10107ZM5.35107 9C5.35107 8.58579 5.68686 8.25 6.10107 8.25H15.1721C15.5863 8.25 15.9221 8.58579 15.9221 9C15.9221 9.41421 15.5863 9.75 15.1721 9.75H6.10107C5.68686 9.75 5.35107 9.41421 5.35107 9ZM6.10107 12.9967C5.68686 12.9967 5.35107 13.3325 5.35107 13.7467C5.35107 14.1609 5.68686 14.4967 6.10107 14.4967H15.1721C15.5863 14.4967 15.9221 14.1609 15.9221 13.7467C15.9221 13.3325 15.5863 12.9967 15.1721 12.9967H6.10107Z"
                fill="#2D3139"
              />
            </svg>
          </button>
        </div>

        <a v-if="!isStandaloneBookingURL" href="#" class="heading-btn" @click.prevent="closeModal">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.49127 14.3952C2.18374 14.7027 2.18374 15.2013 2.49127 15.5088C2.79881 15.8164 3.29743 15.8164 3.60497 15.5088L9 10.1138L14.395 15.5088C14.7026 15.8164 15.2012 15.8164 15.5087 15.5088C15.8163 15.2013 15.8163 14.7027 15.5087 14.3952L10.1137 9.00012L15.5087 3.60509C15.8163 3.29755 15.8163 2.79893 15.5087 2.4914C15.2012 2.18386 14.7026 2.18386 14.395 2.4914L9 7.88643L3.60497 2.4914C3.29743 2.18386 2.79881 2.18386 2.49127 2.4914C2.18374 2.79893 2.18374 3.29755 2.49127 3.60509L7.8863 9.00012L2.49127 14.3952Z"
              fill="#444A55"
            />
          </svg>
        </a>
      </div>
      <div class="heading-btns-wrapper" v-if="isLastStep">
        <a v-if="!isStandaloneBookingURL" href="#" class="heading-btn" @click.prevent="closeModal">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.49127 14.3952C2.18374 14.7027 2.18374 15.2013 2.49127 15.5088C2.79881 15.8164 3.29743 15.8164 3.60497 15.5088L9 10.1138L14.395 15.5088C14.7026 15.8164 15.2012 15.8164 15.5087 15.5088C15.8163 15.2013 15.8163 14.7027 15.5087 14.3952L10.1137 9.00012L15.5087 3.60509C15.8163 3.29755 15.8163 2.79893 15.5087 2.4914C15.2012 2.18386 14.7026 2.18386 14.395 2.4914L9 7.88643L3.60497 2.4914C3.29743 2.18386 2.79881 2.18386 2.49127 2.4914C2.18374 2.79893 2.18374 3.29755 2.49127 3.60509L7.8863 9.00012L2.49127 14.3952Z"
              fill="#444A55"
            />
          </svg>
        </a>
      </div>
    </div>
    <div v-else>
      <div class="heading-btns-wrapper">
        <a v-if="!isStandaloneBookingURL" href="#" class="heading-btn" @click.prevent="closeModal">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.49127 14.3952C2.18374 14.7027 2.18374 15.2013 2.49127 15.5088C2.79881 15.8164 3.29743 15.8164 3.60497 15.5088L9 10.1138L14.395 15.5088C14.7026 15.8164 15.2012 15.8164 15.5087 15.5088C15.8163 15.2013 15.8163 14.7027 15.5087 14.3952L10.1137 9.00012L15.5087 3.60509C15.8163 3.29755 15.8163 2.79893 15.5087 2.4914C15.2012 2.18386 14.7026 2.18386 14.395 2.4914L9 7.88643L3.60497 2.4914C3.29743 2.18386 2.79881 2.18386 2.49127 2.4914C2.18374 2.79893 2.18374 3.29755 2.49127 3.60509L7.8863 9.00012L2.49127 14.3952Z"
              fill="#444A55"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';

export default {
  name: 'CustomWizardHeading',
  props: {
    customerName: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
    },
    isMso: {
      type: Boolean,
      default: true,
    },
    activeIndex: {
      required: true,
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
    displayedSecondStepSubstep: {
      type: String,
      default: '',
    },
    locationsShowListView: {
      type: Boolean,
      default: false,
    },
    locationsShowMapView: {
      type: Boolean,
      default: false,
    },
    showExitSurvey: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showPrevBtn() {
      if (this.isLastStep) {
        return false;
      }
      if (!this.isMso) {
        return (
          this.activeIndex !== 1 ||
          (this.activeIndex === 1 &&
            ['diagnostic-additional-info', 'service-additional-notes', 'diagnostic-appointment-survey'].includes(
              this.displayedSecondStepSubstep,
            ))
        );
      }

      return this.activeIndex && +this.activeIndex !== 0;
    },
    isStandaloneBookingURL() {
      return window.self === window.top;
    },
  },
  methods: {
    closeModal() {
      if (!this.activeIndex || this.isLastStep || this.showExitSurvey) {
        this.$emit('send-abandoned-appointment-notification');
        window.parent.postMessage(
          {
            name: 'searchgenie.closeModal',
          },
          '*',
        );
        return;
      }

      this.$emit('goto-exit-survey');
    },
    handleBackBtnClicked() {
      if (this.displayedSecondStepSubstep === 'diagnostic-appointment-survey') {
        EventBus.$emit('survey-step-back');
        return;
      }

      if (this.activeIndex) {
        this.$emit('prev-tab');
        return;
      }
    },
  },
};
</script>
