<template>
  <div class="max-width">
    <p class="c25 c35">
      <center><strong><span class="c21">{{ getVariable('account.name') }}</span><span
          class="c0">&nbsp;Privacy Policy</span></strong></center>
    </p>
    <a id="id.gjdgxs"></a>
    <p class="c10"><span class="c1">Last Updated: </span><span class="c2">2/28/24</span></p>
    <p class="c10"><span
        class="c5">Your privacy is important to us at </span><span>{{ getVariable('legal_entity.name') }} </span><span
        class="c5">and its affiliates and subsidiaries (collectively “</span><span
        class="c21">{{ getVariable('account.name') }}</span><span class="c5">,” “</span><span class="c0">we</span><span
        class="c5">,” “</span><span class="c0">us</span><span class="c5">,” or “</span><span class="c0">our</span><span
        class="c5">”), and we are committed to safeguarding, preserving, and respecting your privacy rights. &nbsp;This online privacy statement (the “</span><span
        class="c0">Statement</span><span class="c5">” or “</span><span class="c0">Policy</span><span class="c5">”) describes how we collect, use, disclose, and secure the personal information we gather about you through our website, </span><span
        class="c7">{{ getVariable('referring base URL') }} </span><span class="c5">(the “</span><span
        class="c0">Site</span><span class="c5">”), our applications and when you interact with us as a customer or otherwise (collectively, the “</span><span
        class="c0">Services</span><span class="c5">”). </span></p>
    <p class="c3"><span class="c5">For purposes of this Statement, personal information means data that classifies as personal information, personal data, personally identifiable information, or similar terms under applicable data privacy and security laws and regulations. &nbsp;It does not include data excluded or exempted from those laws and regulations, including aggregated, anonymized, or de-identified data. &nbsp;Nothing in this Statement will constitute an admission or evidence that any particular data privacy or information security law or regulation applies to Shopgenie generally or in any specific context. </span>
    </p>
    <p class="c3"><span class="c5">In providing our Services, Shopgenie may collect personal information on behalf and as a service provider for third parties. &nbsp;This Policy does not govern any information we collect on behalf of third parties, and you should consult their privacy policies to become familiar with their data collection and usage practices</span><span
        class="c7 c37">. </span></p>
    <p class="c10"><span class="c12 c7">1. &nbsp;You Consent to This Statement</span></p>
    <p class="c3"><span class="c5">You should read this Statement carefully so that you understand our privacy practices. &nbsp;By accessing, browsing, downloading, or otherwise using the Services, you confirm that you have read, understood, and agreed with this Statement. &nbsp;If you do not agree to this Statement, you may not use the Services.</span>
    </p>
    <p class="c3"><span class="c5">This Statement applies regardless of how the Services are accessed and will cover any technologies or devices by which we make the Services available to you.</span>
    </p>
    <p class="c3"><span class="c5">We may provide you with additional privacy notices where we believe it is appropriate to do so. It is important that you read this Statement together with any other privacy notice or terms we may provide on specific occasions, so that you are fully aware of how and why we are using your data. &nbsp;</span>
    </p>
    <p class="c3"><span class="c5">Your use of our Services, and any dispute over privacy, is subject to this Policy and our Terms of Use incorporated by reference into this Policy. </span>
    </p>
    <p class="c10 c25"><span class="c5">If you have any questions or concerns about our personal information policies or practices, you can contact us in the methods described in the “</span><span
        class="c0">Contact Us</span><span class="c5">” section below.</span></p>
    <p class="c10"><span class="c12 c7">2. What Types of Information Do We Collect? &nbsp;</span></p>
    <p class="c20"><span class="c5">We collect information you voluntarily provide directly to us, information that we collect automatically when you interact with the Services, and information collected from third parties. &nbsp;The categories of personal information that we collect and the purposes for which we collect that information are described below. </span>
    </p>
    <h2 class="c8 c17"><span class="c16 c7 c2">Categories of Personal Information We Collect </span></h2>
    <p class="c20"><span
        class="c5">The following list describes the categories of personal information we collect. </span></p>
    <p class="c20 c27"><span class="c5"></span></p>
    <ul class="c9 lst-kix_list_11-0 start">
      <li class="c15 li-bullet-0"><span class="c1">Account Information </span><span class="c5">includes name, email address, username, and password. &nbsp;We collect this information when you provide it directly to us. &nbsp;We collect this information for the purposes of account creation and management, advertising and marketing, analytics and research, customer service, and website security and maintenance. </span>
      </li>
      <li class="c25 c31 li-bullet-0"><span class="c2 c7">Analytics Information. &nbsp;</span><span class="c7">We collect certain analytics information automatically as you navigate our Services. &nbsp;This includes cookies, tracking pixels, tags or similar tools, which may collect information about your device, geolocation, and interactions with the Services. &nbsp;For more information, please view the “</span><span
          class="c7 c21">How Do We Use “Cookies” and Other Tracking Technologies?</span><span class="c7">”</span><span
          class="c7 c21">&nbsp;</span><span class="c5">section below.</span></li>
      <li class="c31 c25 li-bullet-0"><span class="c7 c2">Chatbot.</span><span class="c5">&nbsp; If you engage with any chatbot feature available through our Services, we will record the conversation. &nbsp;Please note that the information relating to your communications may be shared with our service provider.</span>
      </li>
      <li class="c15 li-bullet-0"><span class="c1">Contact Information </span><span class="c5">includes name, zip code, address, email address, and phone number. We collect this information when you provide it directly to us such as booking a demo, creating an account, or otherwise filling out a form on the Site. &nbsp;</span>
      </li>
      <li class="c15 li-bullet-0"><span class="c1">Payment Information </span><span class="c5">includes name, address, phone number, and debit or credit card information. &nbsp;We use a third party to process your payments, which is required by contract to take steps to safeguard your information. </span>
      </li>
      <li class="c15 li-bullet-0"><span class="c1">Responses to Surveys and Questionnaires </span><span class="c5">includes any information you provide to us when you fill out voluntary news surveys such as an email address if you chose to provide it. &nbsp;We collect this information when you provide it directly to us, including when you respond to our surveys on third party websites. </span>
      </li>
      <li class="c15 li-bullet-0"><span class="c1">Social Media Information </span><span class="c5">includes information that you post by sharing on a blog or another social media platform. &nbsp;We collect this information when you provide it directly to us. &nbsp;Please note that your comments will be visible to the public, so you should never share personal information that you would like to keep private. </span>
      </li>
      <li class="c20 c25 c32 li-bullet-0"><span class="c1">Text Message Opt-In Information </span><span class="c5">includes your opt-in consent to receive marketing messages from us through short codes or similar means. &nbsp;We do not share this information with third parties.</span>
      </li>
    </ul>
    <h2 class="c8"><span class="c16 c7 c2">Other Ways We May Collect, Use, or Share the Information &nbsp;</span></h2>
    <p class="c3"><span class="c5">We may also reserve the right to collect, use, or share personal information for the following purposes:</span>
    </p>
    <ul class="c9 lst-kix_list_11-0">
      <li class="c15 li-bullet-0"><span class="c7 c2">To Provide the Services. &nbsp;</span><span class="c5">We will use your personal information to provide the Services, to improve operations and offerings, and for security purposes. &nbsp;Please note that our Services may involve the use of artificial intelligence or machine learning tools. </span>
      </li>
      <li class="c15 li-bullet-0"><span class="c7 c2">To Present the Site.</span><span class="c5">&nbsp; We will use personal information to present our Site and its contents in a suitable and effective manner for you and your device.</span>
      </li>
      <li class="c15 li-bullet-0"><span class="c7 c2">For Legal Purposes.</span><span class="c7">&nbsp; We reserve the right to cooperate with local, provincial, state, federal and international officials&nbsp;in any investigation requiring either personal information or reports about lawful or unlawful user activity on this site. &nbsp;We also reserve the right to share your personal information to establish, exercise, or defend our legal and property rights, including providing information to others for the purposes of fraud prevention. &nbsp;We may also share your personal information with any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information. </span>
      </li>
      <li class="c15 li-bullet-0"><span class="c7 c2">Business Transactions or Mergers. &nbsp;</span><span class="c5">We reserve the right to share your personal information to third parties as part of any potential business or asset sale, merger, acquisition, investment, round of funding, or similar type of transaction. &nbsp;Additionally, if we are entering into a corporate transaction with a third party, we may receive personal information in connection with the diligence. &nbsp;If we close a transaction, the third party may transfer personal information, which we would use as described in this Policy.</span>
      </li>
      <li class="c15 li-bullet-0"><span class="c7 c2">Bankruptcy or Insolvency.</span><span class="c5">&nbsp; In the event of bankruptcy, insolvency, or dissolution proceedings, we may share your personal information with third parties as part of the sale or reorganization process. </span>
      </li>
      <li class="c15 li-bullet-0"><span class="c7 c2">Service Providers. &nbsp;</span><span class="c5">We use service providers to perform various functions on our behalf. &nbsp;Such service providers will be under contractual obligations to safeguard your personal information and only process it in accordance with our instructions, or as otherwise permitted by applicable laws. &nbsp;We may also receive personal information from service providers. </span>
      </li>
    </ul>
    <p class="c10"><span class="c12 c7">3. &nbsp;How Do We Use “Cookies” and Other Tracking Technologies?&nbsp;</span>
    </p>
    <p class="c3"><span class="c5">We may send one or more cookies to your computer or other device. &nbsp;We may also use other similar technologies such as tracking pixels, tags, or similar tools when you visit our Services. &nbsp;These technologies can collect data regarding your operating system, browser type, device type, screen resolution, IP address, and other technical information, as well as navigation events and session information as you interact with our Services. &nbsp;This information allows us to understand how you use the Services.</span>
    </p>
    <h2 class="c8"><span class="c7 c2 c16">What Are Cookies?</span></h2>
    <p class="c3"><span class="c5">Cookies are small files created by websites, including our Services, that reside on your computer’s hard drive and that store information about your use of a particular website.&nbsp; When you access our Services, we use cookies and other tracking technologies to:</span>
    </p>
    <ul class="c9 lst-kix_list_13-0 start">
      <li class="c4 li-bullet-1"><span class="c5">Estimate our audience size and usage patterns;</span></li>
      <li class="c4 li-bullet-2"><span class="c5">Store information about your preferences, allowing us to customize our Services according to your individual needs;</span>
      </li>
      <li class="c4 li-bullet-2"><span class="c5">Contact you to provide you with information or services that you request from us;</span>
      </li>
      <li class="c4 li-bullet-2"><span class="c5">Advertise new content, events, and services that relate to your interests;</span>
      </li>
      <li class="c4 li-bullet-1"><span class="c5">Provide you with more personalized content that is most relevant to your interest areas; and</span>
      </li>
      <li class="c4 li-bullet-1"><span class="c5">Recognize when you return to our Services.</span></li>
    </ul>
    <p class="c24"><span class="c5">We set some cookies ourselves and others are set by third parties.&nbsp; You can manage your cookies preference as described in the “</span><span
        class="c0">Managing Your Cookies</span><span class="c5">” section below.</span></p>
    <h2 class="c8"><span class="c18">What Types of Cookies Do We Use and Why?</span></h2>
    <p class="c3"><span class="c5">The following chart lists the different types of cookies that we and our service providers use on the Services, examples of who serves those cookies and links to the privacy notices and opt-out information of those cookie servers. </span>
    </p><a id="t.5fbdaefe18a95c090c1c6a44ac9fb2cc37f269f7"></a><a id="t.0"></a>
    <table class="c36">
      <thead>
      <tr class="c11">
        <td class="c6 c29" colspan="1" rowspan="1"><p class="c10"><span class="c0">Types of Cookies</span></p></td>
        <td class="c22 c29" colspan="1" rowspan="1"><p class="c10"><span class="c0">Purpose</span></p></td>
        <td class="c28 c29" colspan="1" rowspan="1"><p class="c10"><span class="c0">Who Serves <br>(for example)</span>
        </p></td>
      </tr>
      </thead>
      <tbody></tbody>
      <tbody>
      <tr class="c11">
        <td class="c6" colspan="1" rowspan="1"><p class="c10"><span class="c5">Essential</span></p></td>
        <td class="c22" colspan="1" rowspan="1"><p class="c10"><span class="c5">These cookies are required for the operation of the Services and enable you to move around the Services and use its features.&nbsp; Disabling these cookies can negatively impact the performance of Services.</span>
        </p></td>
        <td class="c28" colspan="1" rowspan="1"><p class="c13"><span class="c5"><a class="c26"
                                                                                   href="https://www.google.com/url?q=https://www.adobe.com/privacy/cookies.html&amp;sa=D&amp;source=editors&amp;ust=1709222812990268&amp;usg=AOvVaw3zcLIT_6hHM3baYVQiGddW">Adobe</a></span>
        </p>
          <p class="c13"><span class="c5"><a class="c26"
                                             href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1709222812990848&amp;usg=AOvVaw3sJ69UF_a6-bPBoFqJhNIl">Google</a></span>
          </p></td>
      </tr>
      <tr class="c11">
        <td class="c6" colspan="1" rowspan="1"><p class="c10"><span class="c5">Functionality</span></p></td>
        <td class="c22" colspan="1" rowspan="1"><p class="c10"><span class="c1">&nbsp;</span><span class="c5">These cookies are used to recognize you when you return to the Services.&nbsp; This enables us to personalize content for you and remember your preferences. &nbsp;These cookies also enable your interactions with the Services such as emailing us.</span>
        </p></td>
        <td class="c28" colspan="1" rowspan="1"><p class="c13"><span class="c5"><a class="c26"
                                                                                   href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1709222812991796&amp;usg=AOvVaw2biXRIZ7tzT73ZCg9lbGwo">Google</a></span>
        </p></td>
      </tr>
      <tr class="c11">
        <td class="c6" colspan="1" rowspan="1"><p class="c10"><span
            class="c5">Analytics, Performance, and Research</span></p></td>
        <td class="c22" colspan="1" rowspan="1"><p class="c10 c25"><span class="c5">These cookies, beacons, and pixels allow us to analyze activities on the Services.&nbsp; They can be used to improve the functioning of the Services.&nbsp; For example, these cookies recognize and count the number of visitors and see how they move around the Services.&nbsp; Analytics cookies also help us measure the performance of our advertising campaigns to help us improve them and to optimize the content on the Services for those who engage with our advertising.</span>
        </p></td>
        <td class="c28" colspan="1" rowspan="1"><p class="c13"><span class="c5"><a class="c26"
                                                                                   href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1709222812992822&amp;usg=AOvVaw3BzMoufcbOqevvqbka02SU">Google</a></span>
        </p>
          <p class="c13"><span class="c5"><a class="c26"
                                             href="https://www.google.com/url?q=https://m.facebook.com/help/1896641480634370?_rdr&amp;sa=D&amp;source=editors&amp;ust=1709222812993158&amp;usg=AOvVaw1TSn36tJ1-Z2JuiXm-l0QA">Meta</a></span>
          </p>
          <p class="c13"><span class="c14">ReferralRock</span></p></td>
      </tr>
      <tr class="c11">
        <td class="c6" colspan="1" rowspan="1"><p class="c10"><span class="c5">Advertising</span></p></td>
        <td class="c22" colspan="1" rowspan="1"><p class="c10"><span class="c5">These cookies and pixels are used to deliver relevant ads, track ad campaign performance, or track email marketing. &nbsp;</span>
        </p></td>
        <td class="c28" colspan="1" rowspan="1"><p class="c13"><span class="c5"><a class="c26"
                                                                                   href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1709222812993933&amp;usg=AOvVaw3sUzo3rn34gWTaQhTdqbL_">Google</a></span><span
            class="c19">&nbsp;</span></p>
          <p class="c13"><span class="c5"><a class="c26"
                                             href="https://www.google.com/url?q=https://m.facebook.com/help/1896641480634370?_rdr&amp;sa=D&amp;source=editors&amp;ust=1709222812994192&amp;usg=AOvVaw1ky14VkFvD1ixDvPe91VNf">Meta</a></span>
          </p></td>
      </tr>
      </tbody>
    </table>
    <h2 class="c8"><span class="c18">How Long Do Cookies Stay on My Device?</span></h2>
    <p class="c3"><span class="c5">Some cookies operate from the time you visit the Services until the end of that particular browsing session.&nbsp; These cookies, which are called “session cookies,” expire and are automatically deleted when you close your Internet browser.</span>
    </p>
    <p class="c3"><span class="c5">Some cookies will stay on your device between browsing sessions and will not expire or automatically delete when you close your Internet browser.&nbsp; These cookies are called “persistent cookies” and the length of time they will remain on your device will vary from cookie to cookie. &nbsp;Persistent cookies are used for a number of purposes, such as storing your preferences so that they are available for your next visit and to keep a more accurate account of how often you visit the Services, how your use of the Services may change over time, and the effectiveness of advertising efforts.</span>
    </p>
    <h2 class="c8"><span class="c18">Managing Your Cookies</span></h2>
    <p class="c3"><span class="c5">It may be possible to block cookies by changing your Internet browser settings to refuse all or some cookies. &nbsp;If you choose to block all cookies (including essential cookies), you may not be able to access all or parts of the Services.</span>
    </p>
    <p class="c3"><span class="c5">You can find out more about cookies and how to manage them by visiting </span><span
        class="c14">www.AboutCookies.org</span><span class="c5">.</span></p>
    <h2 class="c8"><span class="c18">Does the Site Respond to “Do Not Track” Signals?</span></h2>
    <p class="c3"><span class="c5">At this time, our Site does not respond differently based on a user’s Do Not Track signal.</span>
    </p>
    <p class="c10"><span class="c12 c7">4. Advertising and Marketing Choice</span></p>
    <p class="c10"><span class="c16 c18 c38">Your Marketing Choices</span></p>
    <p class="c10"><span class="c5">We respect your rights in how your personal information is used and shared. &nbsp;If at any time you would like to unsubscribe from receiving future emails, you can email us at </span><span>support@shopgenie.io</span><span
        class="c5">&nbsp;or follow the instructions at the bottom of each email, and we will promptly remove you from marketing correspondence. &nbsp;Please note, however, that we may still need to contact you regarding other matters. </span>
    </p>
    <p class="c10" id="h.30j0zll"><span class="c12 c7">5. &nbsp;How Long Is Your Personal Information Kept</span></p>
    <p class="c10"><span class="c5">We will retain your personal information until the personal information is no longer necessary to accomplish the purpose for which it was provided. &nbsp;We may retain your personal information for longer periods for specific purposes to the extent that we are obliged to do so in accordance with applicable laws and regulations, to protect you, other people, and us from fraud, abuse, an unauthorized access, as necessary to protect our legal rights, or for certain business requirements.</span>
    </p>
    <p class="c20 c25"><span class="c5">We will delete your personal information when it is no longer necessary for the purpose for which it was collected, or upon your request, subject to exceptions as discussed in this Statement or under applicable law, contract, or regulation. </span>
    </p>
    <p class="c20 c27 c25"><span class="c5"></span></p>
    <p class="c10"><span class="c12 c7">6. &nbsp;Our Commitment to Data Security</span></p>
    <p class="c20 c25"><span class="c5">The security of your personal information is important to us. &nbsp;We take various reasonable organizational, administrative, and technical measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. &nbsp;If required by law to do so, we will notify you and/or the relevant supervisory authority in the event of a data breach.</span>
    </p>
    <p class="c20 c27 c25"><span class="c5"></span></p>
    <p class="c20 c25"><span class="c5">However, we cannot and do not guarantee complete security, as it does not exist on the Internet.</span>
    </p>
    <p class="c20 c27 c25"><span class="c30"></span></p>
    <p class="c10"><span class="c7 c12">7. &nbsp;Third Party Links </span></p>
    <p class="c10"><span class="c5">Shopgenie’s Services may contain links to third-party websites.&nbsp; When we provide links, we do so only as a convenience and we are not responsible for any content of any third-party website or any links contained within. &nbsp;It is important to note that this Statement only applies to Shopgenie’s Services.&nbsp; We are not responsible and assume no responsibility for any personal information collected, stored, or used by any third party as a result of you visiting third-party websites.&nbsp; We also advise that you carefully read the privacy notice of any third-party websites you choose to visit.</span>
    </p>
    <p class="c10"><span class="c12 c7">8. &nbsp;Children’s Privacy</span></p>
    <p class="c10"><span class="c5">Protecting the privacy of the very young is especially important.&nbsp; Our Services are not intended for children under 13 years of age, and we do not knowingly collect personal information from children under 13. &nbsp;In the event that we learn that we have collected personal information from a child under age 13 without verification or parental consent, we will immediately delete that information. &nbsp;If you believe that we might have any information from or about a child under 13, please contact us using the information provided in the “</span><span
        class="c0">Contact Us</span><span class="c5">” section below.</span></p>
    <p class="c10"><span class="c12 c7">9. &nbsp;Policy Changes</span></p>
    <p class="c10"><span class="c5">This Statement may change from time to time. &nbsp;If we need to change this Statement at some point in the future, we will post any changes on this page. &nbsp;If we make a significant or material change to this Statement we will notify you via email. &nbsp;You should check these terms when you use the Site. &nbsp;Your continued use of the Site constitutes acceptance of the most current version of this Statement.</span>
    </p>
    <p class="c10"><span class="c12 c7">10. &nbsp;Contact Us</span></p><a id="id.1fob9te"></a>
    <p class="c10"><span
        class="c5">If you have any questions about this Policy, please contact us by email at </span><span>{{
        getVariable('account.email')
      }}</span><span
        class="c7 c33">, </span><span
        class="c5">via phone at {{
        getVariable('account.phone')
      }}, or at </span><span>{{ getVariable('legal_entity.address') }}.</span>
    </p>
    <div><p class="c20"><span class="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p></div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  data: () => ({
    url: "",
    account: {},
  }),
  computed: {
    shopSlug() {
      return this.$route.query.shop;
    },
    isInIframe() {
      return window.self !== window.top;
    },
    parentUrlOrDefault() {
      return window.self !== window.top ? (window.parent.location ?? window.referer) : window.location.href;
    }
  },
  mounted() {
    this.getAccountInfo()
    if (!this.shopSlug) {
      window.location.href = "https://www.shopgenie.io/terms";
    }
    this.url = this.isInIframe ? this.parentUrlOrDefault : 'https://booking.shopgenie.io';
  },
  methods: {
    getVariable(name) {

      const variables = [
        {name: "ACCOUNT.NAME", value: (this.account?.name || "").toUpperCase()},
        {name: "Account.name", value: this.account?.name},
        {name: "account.name", value: this.account?.name},
        {name: "account.phone", value: this.account?.company_phone},
        {name: "account.email", value: this.account?.company_email},
        {name: "referring base", value: this.url},
        {name: "referring base URL", value: this.url},
        {name: "legal_entity.name", value: this.account?.legal_entity?.name},
        {name: "legal_entity.address", value: this.account?.legal_entity?.address},
        {name: "legal_entity.street address", value: this.account?.legal_entity?.address},
        {name: "legal_entity.city", value: this.account?.legal_entity?.city},
        {name: "legal_entity.region", value: this.account?.legal_entity?.region},
        {name: "legal_entity.zip", value: this.account?.legal_entity?.zip},
      ];
      return variables.find(variable => variable?.name === name)?.value || "";
    },
    async getAccountInfo() {
      if (!this.shopSlug) {
        return;
      }
      try {
        let response = await this.$http_client.get("/public/accounts/" + this.shopSlug)
        this.account = response.data.data;
      } catch (error) {
        // if 404 then call public//{company:slug}/accounts/primary
        if (error.response.status === 404) {
          try {
            let response = await this.$http_client.get("/public/" + this.shopSlug + "/accounts/primary")
            this.account = response.data.data;
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
  },
}
</script>

<style>
.max-width {
  max-width: 1200px;
  margin: 0 auto;
}

p {
  margin-bottom: 15px !important
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .max-width {
    max-width: 90%;
  }
}
</style>