<template>
  <div class="add-info-substep">
    <transition name="fade-faster" mode="out-in">
      <customer-phone-otp
        v-if="displayedScreen === 'customer-phone-otp'"
        :account-slug="accountSlug"
        :account-otp-setting="accountOtpSetting"
        :account-name="accountName"
        :first-step="firstStep"
        @customer-phone-confirmed="handleCustomerPhoneConfirmed"
        @start-abandonment-timer="handleStartAbandonmentTimer"
      />

      <first-time-here v-else-if="displayedScreen === 'first-time-here'" />

      <customer-info-form
        v-else-if="displayedScreen === 'customer-info-form'"
        :customer-phone="customerPhone"
        :require-address="requireAddress"
        :account-slug="accountSlug"
        :account-name="accountName"
        @next-step="handleNextStep"
        @start-abandonment-timer="handleStartAbandonmentTimer"
      />
    </transition>
  </div>
</template>

<script>
import CustomerPhoneOtp from './CustomerPhoneOtp';
import FirstTimeHere from './FirstTimeHere';
import CustomerInfoForm from './CustomerInfoForm';

export default {
  name: 'CustomerInfoStep',
  components: {
    CustomerPhoneOtp,
    FirstTimeHere,
    CustomerInfoForm,
  },
  props: {
    requireAddress: {
      type: Boolean,
      required: false,
    },
    accountSlug: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    accountOtpSetting: {
      type: String,
      required: true,
    },
    firstStep:{
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      // customer-phone-otp, first-time-here, customer-info-form
      displayedScreen: null,
      customerPhone: '',
      termsAccepted: false,
    };
  },
  mounted() {
    if (this.isEmbeddedInCrm) {
      this.displayedScreen = 'customer-info-form';
      return;
    }

    this.displayedScreen = 'customer-phone-otp';
  },
  computed: {
    isEmbeddedInCrm() {
      return !!this.$route.query.crm_embed;
    },
  },
  methods: {
    handleNextStep(payload) {
      this.$emit('next-step', {
        ...payload,
        termsAccepted: this.termsAccepted,
      });
    },
    async handleCustomerPhoneConfirmed(payload) {
      const { phone, termsAccepted, recognisedCustomer, recognisedVehicles } = payload;

      if (!recognisedCustomer) {
        this.termsAccepted = termsAccepted;
        this.customerPhone = phone;
        this.displayedScreen = 'first-time-here';
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.displayedScreen = 'customer-info-form';
        return;
      }

      this.$emit('next-step', {
        name: recognisedCustomer.name,
        email: recognisedCustomer.email,
        phone: recognisedCustomer.phone,
        address: recognisedCustomer.address || '',
        recognisedCustomerSlug: recognisedCustomer.slug,
        recognisedVehicles,
        termsAccepted,
      });
    },
    handleStartAbandonmentTimer({ name = '', email = '', phone = '' }) {
      this.$emit('start-abandonment-timer', { name, phone, email });
    },
  },
};
</script>
<style lang="scss">
.terms-checkbox {
  .el-checkbox__label {
    white-space: break-spaces;
    font-size: 13px;
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;
  }

  .el-checkbox__inner::after {
    left: 7px;
    height: 12px;
    width: 4px;
  }
}
</style>
