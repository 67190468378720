<template>
  <div class="location-loader-wrapper">
    <div class="location-loader" v-loading="true"></div>
  </div>
</template>

<script>
export default {
  name: "LocationsLoading",
};
</script>

<style lang="scss">
.location-loader-wrapper {
  height: calc(100vh);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .location-loader {
    height: 100%;
    width: 100%;
  }
}
</style>