<template>
  <div class="map-location-card">
    <div class="card-header">
      <div class="text-header-3">{{ location.name }}</div>
      <div class="location-distance" v-if="location.harvesine_distance">
        {{ location.harvesine_distance }} mi
      </div>
    </div>
    <div class="text-body-4">{{ location.shop_opened_text }}</div>
    <div class="text-body-4 company-address">
      {{ location.company_address }}
    </div>
    <div class="location-card-actions">
      <a
        href="javascript:;"
        @click="handleCallButtonClicked"
        class="primary-btn outline"
      >
        {{ isDesktop ? location.company_phone : "Call Us" }}
      </a>
      <button class="primary-btn" @click.prevent="handleBookNowClicked">
        Book Now
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapCompanyLocationCard",
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDesktop() {
      const { desktop } = this.$route.query;
      if (desktop === undefined) return false;
      return !!Number(desktop);
    },
  },
  methods: {
    analyticsTrackBookNowClick() {
      this.$posthog.capture("locationPickerMap_click_bookNowButton");
    },
    analyticsTrackCallUsClick() {
      this.$posthog.capture("locationPickerMap_click_callUsButton");
    },
    callAction(phone) {
      window.parent.postMessage(
        {
          name: "action.callPhone",
          payload: {
            phone: this.location.company_phone,
          },
        },
        "*"
      );
    },
    handleCallButtonClicked() {
      this.analyticsTrackCallUsClick();
      this.callAction();
    },
    handleBookNowClicked() {
      this.analyticsTrackBookNowClick();
      this.$emit("location-selected", location);
    },
  },
};
</script>

<style lang="scss">
</style>
