import Vue from 'vue';
import VueRouter from 'vue-router';
import GeneralRepair from "@/views/GeneralRepair";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import TermsAndConditions from "@/views/TermsAndConditions";

Vue.use(VueRouter);

const routes = [
    {
        path: '/terms',
        name: 'terms',
        component: TermsAndConditions
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPolicy
    },
    {
        path: '/',
        name: 'general-repair',
        component: GeneralRepair
    },
    {
        path: '/unsubscribe',
        component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/Unsubscribe.vue')
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
