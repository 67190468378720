<template>
  <div class="wizard-progress-wrapper">
    <div
      class="progress-bar"
      :class="{ finished: widthAsPercentage === 100 }"
      :style="`width: ${widthAsPercentage}%`"
    />
  </div>
</template>

<script>
export default {
  name: "CustomWizardProgress",
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  computed: {
    widthAsPercentage() {
      return (this.currentStep / this.totalSteps) * 100;
    },
  },
};
</script>
