<template>
  <div
    class="select-service-step"
    :class="{ 'with-more-padding-bottom': selectedServices.length }"
  >
    <div class="services-section">
      <div
        v-if="diagnosticAppointmentFeatureEnabled && diagnosticService"
        class="select-diagnostic-appt-wrapper"
      >
        <div class="text-header-4 mb-12px">Not sure what's wrong?</div>
        <service-row
          @click.native="
            analyticsTrackDescribeYourIssueClick();
            $emit('diagnostic-appt-selected');
          "
          :service="diagnosticService"
          :loading="loading"
        />
      </div>

      <div class="text-header-4 mb-12px">Instant Booking</div>
      <div class="services-list">
        <service-row
          v-for="service in services"
          :key="service.slug"
          :service="service"
          :loading="loading"
          :active="isServiceSelected(service)"
          @click.native="
            analyticsTrackInstantBookingServiceClick();
            $emit('service-selected', service);
          "
        />
      </div>

      <transition name="fade-faster">
        <div
          v-if="selectedServices.length"
          class="sticked-to-bottom-button-wrapper"
        >
          <div class="content-container">
            <div class="instant-booking-actions-wrapper">
              <button
                class="primary-btn outline"
                @click.prevent="
                  analyticsTrackAddNoteClick();
                  $emit('display-service-additional-notes-substep');
                "
              >
                Add Note
              </button>
              <button
                class="primary-btn"
                @click.prevent="
                  analyticsTrackNextClick();
                  $emit('selected-service-confirmed');
                "
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ServiceRow from "@/components/select-service-step/ServiceRow";

export default {
  name: "SelectServiceSubstep",
  components: {
    ServiceRow,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    services: {
      type: Array,
      default: () => [],
    },
    diagnosticService: {
      type: Object,
      required: true,
    },
    selectedServices: {
      type: Array,
      default: () => [],
    },
    diagnosticAppointmentFeatureEnabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    analyticsTrackDescribeYourIssueClick() {
      this.$posthog.capture("serviceSelection_click_describeYourIssueButton");
    },
    analyticsTrackInstantBookingServiceClick(service) {
      this.$posthog.capture(
        "serviceSelection_click_instantBookingServiceButton",
        { service }
      );
    },
    analyticsTrackNextClick() {
      this.$posthog.capture("serviceSelection_click_nextButton");
    },
    analyticsTrackAddNoteClick() {
      this.$posthog.capture("serviceSelection_click_addNotesButton");
    },
    isServiceSelected(service) {
      return !!this.selectedServices.find((s) => s.slug === service.slug);
    },
  },
};
</script>

<style>
</style>
