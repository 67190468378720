import axios from 'axios';

const searchParams = new URLSearchParams(window.location.search);
const customApiUrl = searchParams.has('custom_api_url') ? searchParams.get('custom_api_url') : null;
const baseURL = customApiUrl || process.env.VUE_APP_API_BASE_URL;

const httpClient = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

export default httpClient;
