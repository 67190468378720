<template>
  <div
    class="ask-for-location-permissions-card"
    :class="{ 'full-height': fullHeight }"
  >
    <div class="text-header-3">Allow us to access your location</div>
    <div class="text-body-4">
      We'll only use this to find a shop closest to you.
    </div>

    <div class="action-buttons-wrapper">
      <button
        @click.prevent="closePermissionsRequest"
        class="action-btn is-cancel"
      >
        No thanks
      </button>
      <button
        @click.prevent="askForPermissions"
        class="action-btn is-confirm"
        :class="{ loading: loading }"
      >
        <lottie-player
          v-if="loading"
          src="/assets/lottie-loader.json"
          class="lottie-location-loading"
          background="transparent"
          autoplay
          loop
        />
        <span v-else>Allow access</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AskForPermissionsCard",
  props: {
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  },
  methods: {
    closePermissionsRequest() {
      this.$emit("close");
      this.$posthog.capture(
        "locationPicker_*_permissionRequestRejectedImmediately"
      );
    },
    getClientCurrentLocation() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { coords } = position;

            resolve({
              lat: coords.latitude,
              lng: coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    async askForPermissions() {
      this.loading = true;
      this.$posthog.capture("locationPicker_*_permissionRequestInitialized");

      if (this.isInIframe) {
        window.parent.postMessage(
          {
            name: "searchgenie.askForPermissions",
          },
          "*"
        );
        return;
      }

      try {
        const result = await this.getClientCurrentLocation();
        window.postMessage(
          {
            name: "embedCode.permissionsGranted",
            payload: result,
          },
          "*"
        );
      } catch (error) {
        window.postMessage(
          {
            name: "embedCode.permissionsDenied",
          },
          "*"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.ask-for-location-permissions-card {
  width: 100%;
  background: #2d3139;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

  .lottie-location-loading {
    width: 22px;
    height: 22px;
    margin: auto;
  }

  &.full-height {
    height: 100%;
  }

  .text-header-3 {
    margin-bottom: 4px;
    color: #ffffff;
  }

  .text-body-4 {
    color: #f1f2f4;
  }

  .action-buttons-wrapper {
    display: flex;
    gap: 12px;
    margin-top: 24px;

    .action-btn {
      border-radius: 8px;
      padding: 10px 20px;
      font-weight: 500;
      font-size: 14px;
      border-style: none;
      flex-grow: 1;
      color: #ffffff;
      width: 50%;
      cursor: pointer;
      transition: all 0.2s;

      &.is-cancel {
        border: 1px solid #ffffff;
        background: #2d3139;
      }

      &.is-confirm {
        background: #337aff;
        border: 1px solid #337aff;

        &.loading {
          background: #003699;
          border-color: #003699;
        }
      }
    }
  }
}
</style>
