<template>
  <div>
    <font-awesome-icon :icon="iconName" :style="{ color, fontSize: size }"/>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faArchive,
  faArrowDown,
  faArrowLink,
  faArrowUp,
  faBookmark,
  faCalendarClock,
  faCampaign,
  faCancel,
  faCannedReply,
  faCar,
  faChair,
  faCheckmark,
  faCircleCheck,
  faCircleDollar,
  faCircleLine,
  faCirclePlus,
  faCircleX,
  faCircleXBold,
  faClock,
  faCompany,
  faCopy,
  faCursor,
  faDesktop,
  faDollar,
  faDownChevron,
  faEdit,
  faEmail,
  faError,
  faErrorBold,
  faExit,
  faExport,
  faFast,
  faFilter,
  faFlag,
  faGear,
  faGenerate,
  faGrip,
  faHint,
  faHintBold,
  faInfo,
  faInfoBold,
  faLeftChevron,
  faList,
  faLock,
  faLockBold,
  faMagic,
  faMagicBold,
  faMenu,
  faMessage,
  faMinus,
  faMobile,
  faMore,
  faNewMessage,
  faNote,
  faOdometer,
  faPaperclip,
  faPause,
  faPhone,
  faPin,
  faPlay,
  faPlayBold,
  faPlus,
  faPreviousBold,
  faReset,
  faRightChevron,
  faSearch,
  faSend,
  faSendBold,
  faSent,
  faSettings,
  faSkipBold,
  faSort,
  faStar,
  faStarBold,
  faTech,
  faTrash,
  faUnread,
  faUpChevron,
  faUser,
  faWarning,
  faWarningBold,
  faWebchat,
  faWrench
} from '@awesome.me/kit-9b3046076c/icons/modules/kit/custom';


library.add(
    faArchive,
    faArrowDown,
    faArrowLink,
    faArrowUp,
    faBookmark,
    faCalendarClock,
    faCampaign,
    faCancel,
    faCannedReply,
    faCar,
    faChair,
    faCheckmark,
    faCircleCheck,
    faCircleDollar,
    faCircleLine,
    faCirclePlus,
    faCircleX,
    faCircleXBold,
    faClock,
    faCompany,
    faCopy,
    faCursor,
    faDesktop,
    faDollar,
    faDownChevron,
    faEdit,
    faEmail,
    faError,
    faErrorBold,
    faExit,
    faExport,
    faFast,
    faFilter,
    faFlag,
    faGear,
    faGenerate,
    faGrip,
    faHint,
    faHintBold,
    faInfo,
    faInfoBold,
    faLeftChevron,
    faList,
    faLock,
    faLockBold,
    faMagic,
    faMagicBold,
    faMenu,
    faMessage,
    faMinus,
    faMobile,
    faMore,
    faNewMessage,
    faNote,
    faOdometer,
    faPaperclip,
    faPause,
    faPhone,
    faPin,
    faPlay,
    faPlayBold,
    faPlus,
    faPreviousBold,
    faReset,
    faRightChevron,
    faSearch,
    faSend,
    faSendBold,
    faSent,
    faSettings,
    faSkipBold,
    faSort,
    faStar,
    faStarBold,
    faTech,
    faTrash,
    faUnread,
    faUpChevron,
    faUser,
    faWarning,
    faWarningBold,
    faWebchat,
    faWrench
);

// NOTE: Whenever you add a new icon in FontAwesome, make sure to add it to this mapping.
const iconMapping = {
  'archive': faArchive,
  'arrow-down': faArrowDown,
  'arrow-link': faArrowLink,
  'arrow-up': faArrowUp,
  'bookmark': faBookmark,
  'calendar-clock': faCalendarClock,
  'campaign': faCampaign,
  'cancel': faCancel,
  'canned-reply': faCannedReply,
  'car': faCar,
  'chair': faChair,
  'checkmark': faCheckmark,
  'circle-check': faCircleCheck,
  'circle-dollar': faCircleDollar,
  'circle-line': faCircleLine,
  'circle-plus': faCirclePlus,
  'circle-x': faCircleX,
  'circle-x-bold': faCircleXBold,
  'clock': faClock,
  'company': faCompany,
  'copy': faCopy,
  'cursor': faCursor,
  'desktop': faDesktop,
  'dollar': faDollar,
  'down-chevron': faDownChevron,
  'edit': faEdit,
  'email': faEmail,
  'error': faError,
  'error-bold': faErrorBold,
  'exit': faExit,
  'export': faExport,
  'fast': faFast,
  'filter': faFilter,
  'flag': faFlag,
  'gear': faGear,
  'generate': faGenerate,
  'grip': faGrip,
  'hint': faHint,
  'hint-bold': faHintBold,
  'info': faInfo,
  'info-bold': faInfoBold,
  'left-chevron': faLeftChevron,
  'list': faList,
  'lock': faLock,
  'lock-bold': faLockBold,
  'magic': faMagic,
  'magic-bold': faMagicBold,
  'menu': faMenu,
  'message': faMessage,
  'minus': faMinus,
  'mobile': faMobile,
  'more': faMore,
  'new-message': faNewMessage,
  'note': faNote,
  'odometer': faOdometer,
  'paperclip': faPaperclip,
  'pause': faPause,
  'phone': faPhone,
  'pin': faPin,
  'play': faPlay,
  'play-bold': faPlayBold,
  'plus': faPlus,
  'previous-bold': faPreviousBold,
  'reset': faReset,
  'right-chevron': faRightChevron,
  'search': faSearch,
  'send': faSend,
  'send-bold': faSendBold,
  'sent': faSent,
  'settings': faSettings,
  'skip-bold': faSkipBold,
  'sort': faSort,
  'star': faStar,
  'star-bold': faStarBold,
  'tech': faTech,
  'trash': faTrash,
  'unread': faUnread,
  'up-chevron': faUpChevron,
  'user': faUser,
  'warning': faWarning,
  'warning-bold': faWarningBold,
  'webchat': faWebchat,
  'wrench': faWrench,
};


export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 16,
    },
    color: {
      type: String,
      required: false,
      default: '#2D3139',
    },
  },
  data() {
    return {
      iconName: iconMapping[this.icon],
    };
  },
};
</script>
