<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 441.685 441.685"
    style="enable-background: new 0 0 441.685 441.685"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M429.179,375.509l-104.78-104.78l-1.568-7.857c-1.792-8.979-8.349-16.208-17.112-18.863
	c-8.765-2.655-18.229-0.283-24.704,6.192l-9.629,9.629l-29.289-29.289l90.885-90.885c7.746-7.747,17.535-13.674,28.308-17.142
	c9.454-3.043,18.182-8.383,25.241-15.443c12.195-12.194,18.761-28.408,18.489-45.654c-0.271-17.246-7.36-33.259-19.96-45.089
	c-2.26-2.121-5.45-2.931-8.45-2.142c-2.998,0.789-5.378,3.064-6.302,6.023l-10.225,32.774c-1.189,3.812-3.791,6.932-7.327,8.787
	c-3.536,1.854-7.581,2.221-11.395,1.031c-3.812-1.189-6.932-3.791-8.786-7.327c-1.854-3.536-2.221-7.583-1.032-11.394l10.107-32.399
	c0.929-2.978,0.25-6.225-1.796-8.581s-5.165-3.485-8.243-2.982c-11.704,1.907-22.555,7.016-31.381,14.775
	c-15.525,13.65-23.428,34.041-21.139,54.544c2.282,20.44-5.01,40.765-20.008,55.762l-61.164,61.165L71.738,60.172l1.896-26.397
	c0.248-3.453-1.506-6.741-4.512-8.458L30.979,3.52c-3.525-2.014-7.959-1.42-10.83,1.45L3.803,21.317
	c-2.87,2.87-3.463,7.305-1.45,10.829l21.796,38.143c1.61,2.817,4.6,4.535,7.812,4.535c0.214,0,0.43-0.008,0.646-0.023l26.407-1.896
	l126.178,126.188l-90.881,90.881c-7.747,7.747-17.536,13.674-28.309,17.142c-9.453,3.043-18.182,8.383-25.241,15.442
	c-12.195,12.195-18.761,28.409-18.489,45.655s7.36,33.259,19.96,45.088c2.26,2.122,5.451,2.932,8.45,2.142
	c2.998-0.789,5.378-3.064,6.302-6.023l10.225-32.774c2.455-7.868,10.852-12.277,18.722-9.817c3.812,1.189,6.932,3.791,8.786,7.327
	c1.854,3.536,2.221,7.583,1.032,11.394l-10.107,32.399c-0.929,2.978-0.25,6.225,1.796,8.581c1.724,1.986,4.212,3.1,6.795,3.1
	c0.48,0,0.965-0.039,1.448-0.117c11.704-1.907,22.555-7.017,31.38-14.775c15.526-13.65,23.429-34.041,21.139-54.544
	c-2.282-20.44,5.01-40.765,20.008-55.762l61.161-61.161l29.289,29.29l-9.625,9.625c-6.475,6.475-8.848,15.941-6.192,24.704
	c2.655,8.763,9.883,15.32,18.862,17.111l7.857,1.568l104.78,104.78c7.56,7.56,17.487,11.34,27.418,11.339
	c9.928-0.001,19.86-3.78,27.418-11.339C444.297,415.228,444.297,390.628,429.179,375.509z M54.047,55.214l-17.08,1.227
	L21.392,29.184l6.625-6.625l27.257,15.576L54.047,55.214z M155.481,291.701c-18.888,18.888-28.062,44.579-25.169,70.487
	c1.637,14.667-4.021,29.256-15.135,39.028c-2.182,1.918-4.539,3.609-7.037,5.056l4.793-15.364c2.621-8.401,1.813-17.32-2.274-25.115
	c-4.087-7.794-10.965-13.53-19.367-16.15c-3.239-1.01-6.553-1.511-9.852-1.511c-5.258,0-10.474,1.273-15.263,3.785
	c-7.794,4.087-13.53,10.965-16.151,19.367L44.999,387.4c-2.989-5.972-4.619-12.585-4.728-19.472
	c-0.194-12.332,4.5-23.924,13.219-32.643c5.121-5.121,11.187-8.834,18.029-11.036c13.48-4.339,25.764-11.79,35.521-21.548
	l164.773-164.773c18.888-18.888,28.062-44.579,25.169-70.487c-1.637-14.666,4.021-29.256,15.135-39.029
	c2.182-1.918,4.539-3.609,7.037-5.056L314.36,38.72c-2.621,8.401-1.813,17.32,2.274,25.115c4.087,7.794,10.965,13.53,19.367,16.15
	s17.321,1.813,25.115-2.274c7.794-4.087,13.53-10.965,16.151-19.367l5.028-16.116c2.989,5.972,4.62,12.585,4.728,19.473
	c0.194,12.331-4.5,23.924-13.219,32.643c-5.121,5.121-11.187,8.833-18.029,11.036c-13.481,4.339-25.764,11.791-35.521,21.548
	L155.481,291.701z M265.226,306.346c-3.593-0.717-4.804-3.511-5.158-4.679s-0.897-4.165,1.694-6.756l31.981-31.981
	c1.585-1.585,3.322-1.997,4.699-1.997c0.875,0,1.604,0.166,2.058,0.304c1.168,0.354,3.963,1.565,4.68,5.159l1.16,5.811l-35.301,35.3
	L265.226,306.346L265.226,306.346z M416.451,417.618c-8.101,8.1-21.281,8.099-29.381,0L286.725,317.273l29.382-29.381
	l100.345,100.345c3.924,3.924,6.084,9.141,6.084,14.69S420.375,413.694,416.451,417.618z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>