<template>
  <div class="sticked-to-bottom-button-wrapper"
  >
    <div class="content-container">
      <button
        type="button"
        class="primary-btn full-width submit-contact-info"
        :disabled="disabled || loading"
        @click.prevent="$emit('btn-click')"
        @touchstart="$emit('btn-click')"
      >
        <span v-if="loading" class="btn-loader"></span>
        <span v-else>{{ text }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StickedToBottomButton',
  props: {
    text: {
      type: String,
      default: 'Next',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      defalt: false,
    },
  },
}
</script>
