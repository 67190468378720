<template>
  <div class="select-location-step-wrapper">
    <el-collapse-transition>
      <div v-if="showLocationsSearch" class="search-locations-input-wrapper">
        <el-input
          class="search-locations-input"
          placeholder="Search locations..."
          v-model="locationsSearch"
        />
      </div>
    </el-collapse-transition>

    <el-collapse-transition>
      <ask-for-permissions-card
        @close="handleCloseAskForPermissionsCard"
        v-if="showAskForPermissionsCard"
      />
    </el-collapse-transition>

    <div
      class="company-location-cards-wrapper"
      :class="{ 'permissions-asked': showAskForPermissionsCard }"
    >
      <company-location-card
        v-for="location in displayedLocations"
        :key="location.slug"
        :location="location"
        :loading="loading"
        @location-selected="$emit('location-selected', location)"
      />
    </div>
  </div>
</template>

<script>
import AskForPermissionsCard from "./AskForPermissionsCard";
import CompanyLocationCard from "./CompanyLocationCard";

export default {
  name: "LocationsList",
  components: {
    CompanyLocationCard,
    AskForPermissionsCard,
  },
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showAskForPermissionsCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locationsSearch: "",
      showLocationsSearch: false,
    };
  },
  mounted() {
    if (this.showAskForPermissionsCard) return;
    setTimeout(() => {
      this.showLocationsSearch = true;
    }, 500);
  },
  watch: {
    locations() {
      if (!this.showAskForPermissionsCard && !this.showLocationsSearch) {
        setTimeout(() => {
          this.showLocationsSearch = true;
        }, 350);
      }
    },
  },
  computed: {
    displayedLocations() {
      if (!this.locationsSearch) return this.locations;

      return this.locations.filter((location) => {
        const nameMatch = location.name
          .toLowerCase()
          .includes(this.locationsSearch.toLowerCase());

        const companyAddressMatch = location.company_address
          .toLowerCase()
          .includes(this.locationsSearch.toLowerCase());

        const companyPhoneMatch = location.company_phone
          .toLowerCase()
          .includes(this.locationsSearch.toLowerCase());

        return nameMatch || companyAddressMatch || companyPhoneMatch;
      });
    },
  },
  methods: {
    handleCloseAskForPermissionsCard() {
      this.$emit("close-ask-for-permissions");
      setTimeout(() => (this.showLocationsSearch = true), 350);
    },
  },
};
</script>

<style lang="scss">
.search-locations-input {
  input {
    padding: 16px;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    border-radius: 0;
    border-color: #e3e5e8 !important;
    color: #17191c;
  }
}
</style>
