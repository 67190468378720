<template>
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1446_29350)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9085 6.87627C14.8334 6.50043 14.4869 6.24173 14.1052 6.27644L8.76268 6.76212C8.57089 6.77955 8.41634 6.86462 8.31918 6.96816C8.22822 7.06509 8.19032 7.17113 8.19548 7.28669C8.47805 13.6082 11.8236 20.0714 16.7359 24.9459C21.6555 29.8277 27.9153 32.8914 33.795 32.7634C33.9453 32.7601 34.1948 32.6319 34.2668 32.2722L35.1909 27.6517C35.2721 27.2455 35.0087 26.8504 34.6025 26.7692L29.5271 25.7541C29.2812 25.7049 29.027 25.7819 28.8497 25.9592L28.0509 26.758C26.9517 27.8572 25.0552 28.4461 23.4 27.3596C21.9346 26.3977 20.1403 24.8332 18.5172 23.2102C16.8942 21.5871 15.3298 19.7928 14.3678 18.3275C13.2813 16.6723 13.8702 14.7757 14.9694 13.6765L15.7682 12.8777C15.9455 12.7004 16.0225 12.4462 15.9733 12.2003L14.9085 6.87627ZM13.7883 2.79081C15.9513 2.59417 17.9146 4.06008 18.3406 6.18986L19.4054 11.5139C19.684 12.9073 19.2479 14.3478 18.2431 15.3526L17.4443 16.1514C17.3394 16.2563 17.3043 16.353 17.2967 16.3961C17.2958 16.4015 17.2953 16.4057 17.2951 16.4088C18.0702 17.5891 19.447 19.1901 20.9921 20.7353C22.5373 22.2804 24.1384 23.6572 25.3187 24.4323C25.3218 24.4321 25.3259 24.4316 25.3313 24.4307C25.3744 24.4232 25.4711 24.388 25.576 24.2831L26.3748 23.4843C27.3796 22.4795 28.8201 22.0434 30.2135 22.3221L35.2889 23.3371C37.5906 23.7975 39.0832 26.0365 38.6229 28.3381L37.6988 32.9586C37.3487 34.7092 35.8725 36.219 33.8712 36.2626C26.7727 36.4171 19.6461 32.7645 14.2706 27.4304C8.88777 22.089 5.02947 14.8368 4.69898 7.44298C4.5969 5.15945 6.39999 3.46248 8.4458 3.27649L13.7883 2.79081Z"
        fill="#2D3139"
      />
    </g>
    <defs>
      <clipPath id="clip0_1446_29350">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(0.5 0.499817)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PhoneIcon",
};
</script>

<style>
</style>