<template>
  <div>
    <div class="need-a-ride-substep-wrapper">
      <div class="text-header-4 mb-12px">
        Need a ride while your car is being worked on?
      </div>

      <div class="ride-options-wrapper">
        <ride-option
          v-for="(rideOption, rideOptionIdx) in rideOptions"
          :key="rideOptionIdx"
          :ride-option="rideOption"
          :selected="rideOption.title === selectedRideOption?.title"
          @click.native="selectedRideOption = rideOption"
        />
      </div>
    </div>

    <transition name="fade-faster">
      <div v-if="selectedRideOption" class="sticked-to-bottom-button-wrapper">
        <div class="content-container">
          <button
            type="button"
            class="primary-btn full-width"
            @click.prevent="$emit('ride-option-selected', selectedRideOption.savedTitle)"
          >
            {{!showBookNowButton ? 'Next' : 'Book now'}}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import RideOption from "@/components/select-appt-time-step/RideOption";

export default {
  name: "NeedARideSubstep",
  components: {
    RideOption,
  },
  props: {
    rideOptions: {
      type: Array,
      default: () => [],
    },
    showBookNowButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedRideOption: null,
    };
  },
};
</script>

<style>
</style>