<template>
  <div class="calendar-no-availability-wrapper">
    <div v-if="withinWorkingHours">
      <div class="icon-wrapper">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.6532 3.86693C33.7493 4.42683 33.5675 4.99852 33.1658 5.40021L28.8738 9.69226L30.3076 11.1261L34.5997 6.83405C35.0014 6.43235 35.5731 6.25063 36.133 6.34667C36.6929 6.44271 37.1713 6.80455 37.4162 7.31715C39.1982 11.0475 38.5474 15.6536 35.4538 18.7471C32.6861 21.5148 28.7078 22.3272 25.2291 21.1916L9.32794 37.0928C8.64452 37.7762 7.53648 37.7762 6.85307 37.0928L2.9071 33.1468C2.22368 32.4634 2.22368 31.3553 2.90709 30.6719L18.8083 14.7708C17.6727 11.2921 18.4851 7.31375 21.2528 4.54607C24.3463 1.45253 28.9524 0.8017 32.6827 2.5837C33.1953 2.82857 33.5572 3.30702 33.6532 3.86693ZM28.5098 5.1065C26.783 5.06567 25.0439 5.70474 23.7277 7.02095C21.7211 9.02754 21.2882 12.0171 22.4391 14.4475C22.7556 15.1158 22.6178 15.911 22.0949 16.4339L6.61941 31.9094L8.0905 33.3805L23.566 17.905C24.0889 17.3821 24.8841 17.2443 25.5524 17.5608C27.9828 18.7117 30.9723 18.2788 32.9789 16.2722C34.2952 14.956 34.9342 13.2169 34.8934 11.4901L31.5451 14.8384C30.8617 15.5218 29.7536 15.5218 29.0702 14.8384L25.1615 10.9297C24.4781 10.2463 24.4781 9.13824 25.1615 8.45482L28.5098 5.1065Z"
            fill="#2D3139"
          />
        </svg>
      </div>

      <div class="text-header-2">We're open, let's get you in!</div>

      <div class="text-header-4">
        Looks like our online availability is full, but give us a call and we
        can work something out.
      </div>

      <a
        :href="`tel:${accountNumber}`"
        v-if="isDesktop"
        class="primary-btn full-width is-call-us-btn"
      >
        Call us: {{ accountNumber }}
      </a>

      <a :href="`tel:${accountNumber}`" v-else class="primary-btn full-width">
        Call us
      </a>
    </div>

    <div v-else>
      <div class="text-header-2">
        Need to
        {{
          appointmentType === "drop_off"
            ? "drop-off"
            : "wait with your vehicle"
        }}?
      </div>

      <div class="text-header-4">
        Looks like our online availability is full, but click next,
        <strong>leave your message and we'll reach out.</strong>
      </div>

      <button
        @click.prevent="$emit('show-contact-form')"
        class="primary-btn full-width"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoAvailability",
  props: {
    withinWorkingHours: {
      type: Boolean,
      default: true,
    },
    appointmentType: {
      type: String,
      required: true,
    },
    accountNumber: {
      type: String,
      default: "",
    },
  },
  computed: {
    isDesktop() {
      const { desktop } = this.$route.query;
      if (desktop === undefined) return false;
      return !!Number(desktop);
    },
  },
};
</script>

<style lang="scss">
.calendar-no-availability-wrapper {
  padding: 96px 0;
  text-align: center;

  .text-header-4 {
    margin-bottom: 32px;
  }

  .primary-btn.is-call-us-btn {
    background-color: #fff1ed;
    color: #992805;
    border: 1px dashed #ffb39c;
    font-size: 16px;

    &:hover {
      box-shadow: none;
    }
  }

  .icon-wrapper {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>