<template>
  <div class="contact-form-wrapper">
    <transition name="fade-faster" mode="out-in">
      <div
        key="form-submitted"
        class="form-submitted-wrapper"
        v-if="formSubmitted"
      >
        <div class="text-header-2">
          Your message has been sent successfully!
        </div>
        <div class="text-header-4">
          We'll reach out to you as soon as possible.
        </div>
      </div>

      <div key="form" v-else>
        <div class="form-group">
          <label class="form-label">Full name</label>
          <el-input placeholder="Ex:John Smith" v-model="name" />
        </div>

        <div class="form-group">
          <label class="form-label">Phone number</label>
          <el-input
            placeholder="Enter a number"
            type="tel"
            v-model="phone"
            inputmode="tel"
            oninput="this.value = this.value.replace(/[^\d+]+/g, '').replace(/(\..*)\./g, '$1')"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Message</label>
          <el-input
            type="textarea"
            rows="4"
            placeholder="Enter your question..."
            v-model="message"
          />
        </div>

        <div class="text-body-5">
          By submitting, you agree to receive SMS and email notifications about
          my appointment from {{ shopname }}. Messaging and data rates may
          apply. Reply STOP to opt-out at any time. Our
          <router-link
            :to="'/terms?shop=' + this.$route.query.shop"
            target="_blank"
          >
            <span class="text-primary">Terms & Conditions.</span>
          </router-link>
        </div>

        <transition name="fade-faster">
          <div class="mt-12px" v-if="error">
            <el-alert
              class="text-left"
              effect="dark"
              :title="error"
              type="error"
            />
          </div>
        </transition>
      </div>
    </transition>

    <transition name="fade-faster">
      <sticked-to-bottom-button
        @btn-click="submitContactForm"
        v-if="name && phone && message && !formSubmitted"
        :loading="loading"
      />
    </transition>
  </div>
</template>

<script>
import StickedToBottomButton from "@/components/global/StickedToBottomButton";

import CustomersService from "@/services/CustomersService";

export default {
  name: "ContactForm",
  components: {
    StickedToBottomButton,
  },
  props: {
    shopname: {
      type: String,
      required: true,
    },
    accountSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      phone: "",
      message: "",
      loading: false,
      error: "",
      formSubmitted: false,
    };
  },
  methods: {
    async submitContactForm() {
      try {
        this.error = null;
        this.loading = true;
        await CustomersService.post.contact(
          this.accountSlug,
          this.name,
          this.phone,
          this.message
        );
        this.loading = false;
        this.formSubmitted = true;
      } catch (error) {
        this.error = "Something went wrong. Please try again later.";
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.contact-form-wrapper {
  padding: 24px 0;

  .form-submitted-wrapper {
    text-align: center;
    padding: 64px 0;
  }
}
</style>
