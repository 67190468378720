import apiClient from '@/client';

export default {
  get: {
    show: slug => {
      return apiClient.get(`/public/companies/${slug}`);
    },
    companyAccounts: company => {
      return apiClient.get(`/public/companies/${company}/accounts?booking-tool-settings-included=1`);
    }
  }
};
