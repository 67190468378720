<template>
  <div class="ride-option" :class="{ selected: selected }">
    <div class="text-body-4">{{ rideOption.title }}</div>
    <div v-if="rideOption.description" class="text-body-5">
      {{ rideOption.description }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RideOption",
  props: {
    rideOption: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>