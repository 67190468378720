<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.6667 4.50977C25.6122 4.50977 28 6.89758 28 9.8431V19.1764C28 22.122 25.6122 24.5098 22.6667 24.5098H11.036C10.7943 24.5098 10.5572 24.5754 10.35 24.6998L6.01933 27.2982C5.13063 27.8314 4 27.1912 4 26.1548V9.8431C4 6.89758 6.38782 4.50977 9.33333 4.50977H22.6667ZM9.33333 6.50977H22.6667C24.5076 6.50977 26 8.00215 26 9.8431V19.1764C26 21.0174 24.5076 22.5098 22.6667 22.5098H11.036C10.4318 22.5098 9.83904 22.674 9.32099 22.9848L6 24.9774V9.8431C6 8.00215 7.49238 6.50977 9.33333 6.50977Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>