<template>
  <div class="calendar-controls">
    <div class="selected-month">{{ selectedMonth }} {{ selectedYear }}</div>

    <div class="control-actions-wrapper">
      <a href="#" @click.prevent="$emit('prev-week')">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0341 15.4981C11.3416 15.8056 11.8402 15.8056 12.1478 15.4981C12.4553 15.1906 12.4553 14.6919 12.1478 14.3844L6.75276 8.98938L12.1478 3.59435C12.4553 3.28681 12.4553 2.78819 12.1478 2.48065C11.8402 2.17312 11.3416 2.17312 11.0341 2.48065L5.08277 8.43198C5.04433 8.47042 5.01014 8.5124 4.98131 8.55607C4.77948 8.86174 4.81312 9.27713 5.08222 9.54623L11.0341 15.4981Z"
            fill="#337AFF"
          />
        </svg>
      </a>
      <a href="#" class="cursor-pointer" @click.prevent="$emit('next-week')">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.96935 2.4914C6.66181 2.18386 6.16319 2.18386 5.85565 2.4914C5.54812 2.79893 5.54812 3.29755 5.85565 3.60509L11.2507 9.00012L5.85565 14.3952C5.54812 14.7027 5.54812 15.2013 5.85565 15.5088C6.16319 15.8164 6.66181 15.8164 6.96935 15.5088L12.9207 9.55752C12.9205 9.5577 12.9209 9.55733 12.9207 9.55752C12.9591 9.51908 12.9933 9.4771 13.0221 9.43343C13.224 9.12776 13.1903 8.71237 12.9212 8.44327C12.921 8.44309 12.9214 8.44346 12.9212 8.44327L6.96935 2.4914Z"
            fill="#337AFF"
          />
        </svg>
      </a>
    </div>
    <!-- <div class="selected-month">{{ selectedMonth }} {{ selectedYear }}</div> -->
  </div>
</template>

<script>
export default {
  name: "CalendarControls",
  props: {
    selectedMonth: {
      type: String,
      required: false,
    },
    selectedYear: {
      type: String,
      requird: false,
    },
  },
};
</script>

<style>
</style>