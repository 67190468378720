<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7635 7.8409H15.6953L12.9979 12.1108V12.179H15.7635V7.8409Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2.5H18C18.8284 2.5 19.5 3.17157 19.5 4V18C19.5 18.8284 18.8284 19.5 18 19.5H4C3.17157 19.5 2.5 18.8284 2.5 18V4C2.5 3.17157 3.17157 2.5 4 2.5ZM11.6172 12.2301V13.2954H15.755V15H17.0121V13.2954H18.1669V12.179H17.0121V6.27272H15.3885L11.6172 12.2301ZM5.14631 15V6.27272H6.46307V13.8665H10.4176V15H5.14631Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.66667 0C1.64162 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.64162 22 3.66667 22H18.3333C20.3584 22 22 20.3584 22 18.3333V3.66667C22 1.64162 20.3584 0 18.3333 0H3.66667ZM2 4C2 2.89543 2.89543 2 4 2H18C19.1046 2 20 2.89543 20 4V18C20 19.1046 19.1046 20 18 20H4C2.89543 20 2 19.1046 2 18V4Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>