<template>
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="-0.00012207" width="40" height="40" rx="8" fill="#E5EEFF"/>
  <path d="M18.8333 23.9998C18.281 23.9998 17.8333 24.4476 17.8333 24.9998C17.8333 25.5521 18.281 25.9998 18.8333 25.9998H22.1666C22.7189 25.9998 23.1666 25.5521 23.1666 24.9998C23.1666 24.4476 22.7189 23.9998 22.1666 23.9998H18.8333Z" fill="#003699"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8333 13.3332C13.8333 11.8604 15.0272 10.6665 16.5 10.6665H24.5C25.9727 10.6665 27.1666 11.8604 27.1666 13.3332V26.6665C27.1666 28.1393 25.9727 29.3332 24.5 29.3332H16.5C15.0272 29.3332 13.8333 28.1393 13.8333 26.6665V13.3332ZM16.5 12.6665H24.5C24.8682 12.6665 25.1666 12.965 25.1666 13.3332V26.6665C25.1666 27.0347 24.8682 27.3332 24.5 27.3332H16.5C16.1318 27.3332 15.8333 27.0347 15.8333 26.6665V13.3332C15.8333 12.965 16.1318 12.6665 16.5 12.6665Z" fill="#003699"/>
  </svg>
</template>

<script>
export default {
  name: "MobilePhoneIcon",
};
</script>

<style>
</style>