<template>
  <div class="first-time-here-wrapper">
    <div class="text-header-1 mb-8px">First time here? 🎉</div>
    <div class="text-body-4">
      We'll almost ready to get you booked, just a few quick steps left.
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstTimeHere",
};
</script>

<style lang="scss">
.first-time-here-wrapper {
  padding: 0 16px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>