<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2.5H18C18.8284 2.5 19.5 3.17157 19.5 4V18C19.5 18.8284 18.8284 19.5 18 19.5H4C3.17157 19.5 2.5 18.8284 2.5 18V4C2.5 3.17157 3.17157 2.5 4 2.5ZM12.8292 14.8168C13.2894 15.0185 13.8121 15.1193 14.3974 15.1193C14.994 15.1193 15.5238 15.0114 15.9869 14.7955C16.4499 14.5767 16.815 14.277 17.082 13.8963C17.3519 13.5156 17.4854 13.0852 17.4826 12.6051C17.4854 12.054 17.3264 11.5923 17.0053 11.2202C16.6871 10.848 16.2383 10.6179 15.6587 10.5298V10.4617C16.1133 10.3452 16.4769 10.1165 16.7496 9.77557C17.0252 9.43466 17.1616 9.01989 17.1587 8.53125C17.1616 8.10228 17.0508 7.70739 16.8264 7.34659C16.6048 6.9858 16.2894 6.69745 15.8803 6.48154C15.4741 6.26279 14.9911 6.15341 14.4315 6.15341C13.8974 6.15341 13.4116 6.25426 12.9741 6.45597C12.5366 6.65767 12.1843 6.9375 11.9173 7.29546C11.6531 7.65341 11.5139 8.06818 11.4996 8.53977H12.7738C12.7852 8.27273 12.8675 8.04404 13.021 7.8537C13.1744 7.66051 13.3732 7.51279 13.6175 7.41051C13.8647 7.3054 14.1303 7.25284 14.4144 7.25284C14.7127 7.25284 14.9712 7.30966 15.19 7.4233C15.4116 7.53693 15.582 7.69461 15.7014 7.89631C15.8235 8.09517 15.8846 8.32955 15.8846 8.59943C15.8846 8.88068 15.815 9.12642 15.6758 9.33665C15.5366 9.54404 15.3434 9.70597 15.0962 9.82245C14.8519 9.93892 14.5678 9.99716 14.244 9.99716H13.5067V11.071H14.244C14.6474 11.071 14.9911 11.1349 15.2752 11.2628C15.5621 11.3906 15.7795 11.5668 15.9272 11.7912C16.0778 12.0128 16.1531 12.2699 16.1531 12.5625C16.1531 12.8438 16.0778 13.0923 15.9272 13.3082C15.7766 13.5213 15.5678 13.6875 15.3008 13.8068C15.0366 13.9261 14.7326 13.9858 14.3889 13.9858C14.0764 13.9858 13.7923 13.9347 13.5366 13.8324C13.2809 13.7301 13.0735 13.5852 12.9144 13.3977C12.7582 13.2074 12.6715 12.983 12.6545 12.7244H11.3164C11.3363 13.1989 11.4783 13.6165 11.7425 13.9773C12.0096 14.3352 12.3718 14.6151 12.8292 14.8168ZM4.7049 15V6.27273H6.02166V13.8665H9.97621V15H4.7049Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.66667 0C1.64162 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.64162 22 3.66667 22H18.3333C20.3584 22 22 20.3584 22 18.3333V3.66667C22 1.64162 20.3584 0 18.3333 0H3.66667ZM2 4C2 2.89543 2.89543 2 4 2H18C19.1046 2 20 2.89543 20 4V18C20 19.1046 19.1046 20 18 20H4C2.89543 20 2 19.1046 2 18V4Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>