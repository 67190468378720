<template>
  <div class="customer-otp-wrapper">
    <transition name="fade-fastest" mode="out-in">
      <enter-customer-phone
        v-if="!otpSent && accountOtpSetting === 'sms'"
        :account-slug="accountSlug"
        :account-name="accountName"
        :account-otp-setting="accountOtpSetting"
        :first-step="firstStep"
        @otp-sent="handleOTPSent"
        @start-abandonment-timer="handleStartAbandonmentTimer"
      />

      <enter-customer-phone-for-email-otp
        v-else-if="!otpSent && accountOtpSetting === 'email'"
        :account-slug="accountSlug"
        :account-name="accountName"
        :account-otp-setting="accountOtpSetting"
        @otp-sent="handleOTPSent"
        @start-abandonment-timer="handleStartAbandonmentTimer"
      />

      <verify-customer-phone
        v-else
        :account-slug="accountSlug"
        :account-otp-setting="accountOtpSetting"
        :customer-phone="phone"
        :customer-email="email"
        @customer-phone-confirmed="handleCustomerPhoneConfirmed"
      />
    </transition>
  </div>
</template>

<script>
import EnterCustomerPhone from './EnterCustomerPhone';
import VerifyCustomerPhone from './VerifyCustomerPhone';
import EnterCustomerPhoneForEmailOtp from './EnterCustomerPhoneForEmailOtp';

export default {
  name: 'CustomerPhoneOtp',
  props: {
    accountSlug: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    accountOtpSetting: {
      type: String,
      required: true,
    },
    firstStep:{
      type: Boolean,
      required: false,
    }
  },
  components: {
    EnterCustomerPhone,
    VerifyCustomerPhone,
    EnterCustomerPhoneForEmailOtp,
  },
  data() {
    return {
      phone: '',
      email: '',
      termsAccepted: false,
      otpSent: false,
    };
  },
  methods: {
    handleOTPSent({ phone, email, otpSent, termsAccepted }) {
      this.phone = phone;
      this.email = email;
      this.termsAccepted = termsAccepted;

      if (otpSent) {
        this.otpSent = true;
        return;
      }

      this.$emit('customer-phone-confirmed', {
        phone: this.phone,
        email: this.email,
        accountOtpSetting: this.accountOtpSetting,
        termsAccepted: this.termsAccepted,
        recognisedCustomer: null,
        recognisedVehicles: [],
      });
    },
    handleCustomerPhoneConfirmed(payload) {
      this.$emit('customer-phone-confirmed', {
        ...payload,
        phone: this.phone,
        email: this.email,
        accountOtpSetting: this.accountOtpSetting,
        termsAccepted: this.termsAccepted,
      });
    },
    handleStartAbandonmentTimer({ email = '', phone = '' }) {
      this.$emit('start-abandonment-timer', { phone, email });
    },
  },
};
</script>

<style lang="scss">
.customer-otp-wrapper {
  padding: 0 16px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .otp-enter-phone-wrapper,
  .otp-enter-code-wrapper {
    text-align: center;
  }

  .otp-enter-code-wrapper {
    .verify-otp-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .resend-btn {
      background: white;
      border: 1px solid #e3e5e8;
      border-radius: 8px;
      color: #2d3139;
      cursor: pointer;
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      font-size: 14px;

      &:hover {
        box-shadow: 0px 1px 2px 0px rgba(23, 25, 28, 0.05);
      }

      .btn-loader {
        border-color: #377aff;
        position: absolute;
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>
