<template>
  <div class="diagnostic-appt-survey-wrapper">
    <!-- <div class="text-header-4 mb-12px">Select all that apply</div> -->

    <transition name="fade-faster" mode="out-in">
      <div :key="currentOptionsKey">
        <div class="diagnostic-survey-steps-wrapper">
          <survey-step
            v-for="option in displayedOptions"
            :key="option"
            :text="option"
            :has-arrow="isOnFirstStep"
            :icon="getSurveyIcon(option)"
            :selected="selectedAnswers.includes(option)"
            @click.native="() => handleOptionSelected(option)"
          />
        </div>

        <div
          v-if="selectedAnswers.includes('Warning light turned on')"
          class="warning-code-input-wrapper"
        >
          <div class="text-header-4 mb-12px">Enter warning code (optional)</div>
          <el-input placeholder="Enter code" v-model="warningCode" />
        </div>
      </div>
    </transition>

    <transition name="fade-faster">
      <div v-if="showNextStepButton" class="sticked-to-bottom-button-wrapper">
        <div class="content-container">
          <button
            @click.prevent="completeSurvey"
            class="primary-btn full-width"
          >
            Next
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import SurveyStep from "@/components/select-service-step/SurveyStep";

export default {
  name: "DiagnosticAppointmentSurvey",
  components: {
    SurveyStep,
  },
  data() {
    return {
      surveyData: [
        {
          problem: "Vehicle won't start",
          headerTitle: "Please tell us more",
          multiselect: true,
          possibleAnswers: [
            { answer: "Turned the key, nothing happens", subAnswers: [] },
            { answer: "Engine sounds like it'll start", subAnswers: [] },
            { answer: "Clicking sound", subAnswers: [] },
            { answer: "Other" },
          ],
        },
        {
          problem: "Warning light turned on",
          headerTitle: "Describe the warning",
          multiselect: true,
          possibleAnswers: [
            { icon: "engine_light", answer: "Engine light", subAnswers: [] },
            { icon: "oil_pressure", answer: "Oil pressure", subAnswers: [] },
            { icon: "battery", answer: "Battery", subAnswers: [] },
            { icon: "tire_warning", answer: "Tire warning", subAnswers: [] },
            { icon: "abs", answer: "ABS", subAnswers: [] },
            { icon: "other", answer: "Other" },
          ],
        },
        {
          problem: "I hear a strange noise",
          headerTitle: "Describe the sound",
          multiselect: true,
          possibleAnswers: [
            { answer: "Squeaky sound", subAnswers: [] },
            { answer: "Rumbling", subAnswers: [] },
            { answer: "Clicking", subAnswers: [] },
            { answer: "Rattling", subAnswers: [] },
            { answer: "Banging sound", subAnswers: [] },
            { answer: "Other" },
          ],
        },
        {
          problem: "The car smells weird",
          headerTitle: "Describe the smell",
          multiselect: false,
          possibleAnswers: [
            { answer: "Gas smell", subAnswers: [] },
            { answer: "Burnt smell", subAnswers: [] },
            { answer: "Rotten eggs", subAnswers: [] },
            { answer: "Other" },
          ],
        },
        {
          problem: "I see smoke",
          headerTitle: "Describe the smoke",
          multiselect: true,
          possibleAnswers: [
            { answer: "Under the hood", subAnswers: [] },
            { answer: "Somewhere outside the vehicle", subAnswers: [] },
            { answer: "Other" },
          ],
        },
        {
          problem: "I see a leak",
          headerTitle: "Describe the fluid",
          multiselect: false,
          possibleAnswers: [
            { answer: "Dark fluid color", subAnswers: [] },
            { answer: "Inside the vehicle", subAnswers: [] },
            { answer: "Under the vehicle", subAnswers: [] },
            { answer: "Other" },
          ],
        },
        {
          problem: "Other",
        },
      ],
      currentOptionsKey: 0,
      displayedOptions: [],
      isOnFirstStep: true,
      isMultiselect: false,
      selectedAnswers: [],
      warningCode: "",
    };
  },
  mounted() {
    this.displayedOptions = this.surveyData.map((step) => step.problem);

    EventBus.$on("survey-step-back", () => {
      if (this.isOnFirstStep) {
        this.$emit("display-select-service-substep");
        return;
      }

      this._surveyGoBack();
    });
  },
  computed: {
    showNextStepButton() {
      return !this.isOnFirstStep && this.selectedAnswers.length > 1;
    },
  },
  methods: {
    _surveyGoBack() {
      this.currentOptionsKey -= 1;
      this.selectedAnswers = [];
      this.isOnFirstStep = true;
      this.displayedOptions = this.surveyData.map((step) => step.problem);
      this.$emit("header-title-change", "What brings you in?");
    },
    _goToDiagnosticAdditionalInfoSubstep() {
      this.$emit("display-diagnostic-additional-info-substep", {
        answers: this.warningCode
          ? [...this.selectedAnswers, this.warningCode]
          : this.selectedAnswers,
      });
    },
    _populateAnswersFromFirstStepSelectedProblem(selectedProblem) {
      const currentProblemData = this.surveyData.find(
        ({ problem }) => problem === selectedProblem
      );

      this.displayedOptions = currentProblemData.possibleAnswers.map(
        ({ answer }) => answer
      );
      this.currentOptionsKey += 1;
      this.selectedAnswers.push(selectedProblem);
      this.isOnFirstStep = false;
      this.$emit("header-title-change", currentProblemData.headerTitle);
    },
    getSurveyIcon(option) {
      if (this.isOnFirstStep) return null;

      if (
        this.selectedAnswers.includes("Warning light turned on") &&
        option === "Other"
      ) {
        return "other";
      }

      const currentOptionsData = this.surveyData.find((data) => {
        return data.possibleAnswers.some(({ answer }) => answer === option);
      });

      const optionData = currentOptionsData.possibleAnswers.find(
        ({ answer }) => answer === option
      );

      return optionData.icon || null;
    },
    handleOptionSelected(option) {
      if (this.isOnFirstStep && option.toLowerCase() === "other") {
        this._goToDiagnosticAdditionalInfoSubstep();
        return;
      }

      if (this.isOnFirstStep) {
        this.$posthog.capture("serviceSelection_click_diagnosticProblemOptionButton")
        this._populateAnswersFromFirstStepSelectedProblem(option);
        return;
      }

      if (!this.selectedAnswers.includes(option)) {
        this.selectedAnswers.push(option);
        return;
      }

      this.selectedAnswers = this.selectedAnswers.filter(
        (answer) => answer !== option
      );
    },
    completeSurvey() {
      this.$posthog.capture("serviceSelection_click_diagnosticProblemNextButton")
      this._goToDiagnosticAdditionalInfoSubstep();
    },
  },
};
</script>
