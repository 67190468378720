<template>
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.0336914" y="-0.00012207" width="40" height="40" rx="8" fill="#E5EEFF"/>
<path d="M27.5767 14.8656C29.0494 14.8656 30.2433 13.6717 30.2433 12.1989C30.2433 10.7261 29.0494 9.53223 27.5767 9.53223C26.1039 9.53223 24.91 10.7261 24.91 12.1989C24.91 13.6717 26.1039 14.8656 27.5767 14.8656Z" fill="#003699"/>
<path d="M11.8242 14.8656C11.8242 13.9451 12.5704 13.1989 13.4909 13.1989H21.454C22.0063 13.1989 22.454 12.7512 22.454 12.1989C22.454 11.6466 22.0063 11.1989 21.454 11.1989H13.4909C11.4658 11.1989 9.82422 12.8405 9.82422 14.8656V21.3553C9.82422 22.9203 10.8041 24.2555 12.1842 24.782V27.4581C12.1842 28.8453 13.8796 29.5194 14.8322 28.511L18.128 25.022H24.9101C26.9351 25.022 28.5767 23.3803 28.5767 21.3553V18.1014C28.5767 17.5491 28.129 17.1014 27.5767 17.1014C27.0244 17.1014 26.5767 17.5491 26.5767 18.1014V21.3553C26.5767 22.2758 25.8305 23.022 24.9101 23.022H17.697C17.4219 23.022 17.1589 23.1353 16.97 23.3353L14.1842 26.2844V24.0045C14.1842 23.4962 13.8029 23.0688 13.2979 23.011C12.4688 22.9161 11.8242 22.2102 11.8242 21.3553V14.8656Z" fill="#003699"/>
</svg>
</template>

<script>
export default {
  name: "UnreadMessageIconColored",
};
</script>

<style>
</style>