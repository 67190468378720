<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3535 13.7642C15.0893 13.929 14.7896 14.0114 14.4544 14.0114C14.1191 14.0114 13.818 13.9276 13.551 13.7599C13.2868 13.5895 13.0779 13.3651 12.9245 13.0867C12.774 12.8082 12.6987 12.5057 12.6987 12.179C12.6987 11.9347 12.7441 11.7031 12.8351 11.4844C12.9288 11.2628 13.0566 11.0668 13.2186 10.8963C13.3805 10.7259 13.568 10.5923 13.7811 10.4957C13.997 10.3992 14.2314 10.3509 14.4842 10.3509C14.8166 10.3509 15.112 10.4318 15.3706 10.5938C15.6319 10.7557 15.8365 10.9744 15.9842 11.25C16.1348 11.5256 16.2101 11.831 16.2101 12.1662C16.2101 12.5099 16.1333 12.821 15.9799 13.0994C15.8265 13.3778 15.6177 13.5994 15.3535 13.7642Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2.5H18C18.8284 2.5 19.5 3.17157 19.5 4V18C19.5 18.8284 18.8284 19.5 18 19.5H4C3.17157 19.5 2.5 18.8284 2.5 18V4C2.5 3.17157 3.17157 2.5 4 2.5ZM13.3081 14.9063C13.6859 15.0426 14.0694 15.1136 14.4586 15.1193C15.0666 15.125 15.5978 14.9972 16.0524 14.7358C16.5069 14.4716 16.8592 14.1136 17.1092 13.6619C17.362 13.2102 17.4885 12.706 17.4885 12.1492C17.4885 11.5923 17.3663 11.098 17.122 10.6662C16.8777 10.2344 16.5495 9.89631 16.1376 9.65199C15.7285 9.40483 15.2711 9.28125 14.7654 9.28125C14.4615 9.28125 14.1717 9.32955 13.8961 9.42614C13.6234 9.51989 13.3762 9.65483 13.1547 9.83097C12.9359 10.0043 12.7512 10.2145 12.6007 10.4617H12.5368C12.5396 9.47017 12.7186 8.69603 13.0737 8.13921C13.4316 7.58239 13.9302 7.30398 14.5694 7.30398C14.9899 7.30398 15.3308 7.41904 15.5922 7.64915C15.8535 7.87926 16.0282 8.17614 16.1163 8.53977H17.416C17.3478 8.07955 17.1873 7.66904 16.9345 7.30824C16.6845 6.94745 16.3578 6.66478 15.9544 6.46023C15.551 6.25568 15.0893 6.15341 14.5694 6.15341C14.0637 6.15341 13.6078 6.25995 13.2015 6.47301C12.7953 6.68608 12.4487 6.99716 12.1618 7.40625C11.8748 7.8125 11.6547 8.31108 11.5012 8.90199C11.3507 9.4929 11.2754 10.169 11.2754 10.9304C11.2754 11.7344 11.3663 12.4048 11.5481 12.9418C11.7328 13.4787 11.9785 13.9063 12.2853 14.2244C12.5922 14.5426 12.9331 14.7699 13.3081 14.9063ZM4.75764 15V6.27273H6.0744V13.8665H10.0289V15H4.75764Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.66667 0C1.64162 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.64162 22 3.66667 22H18.3333C20.3584 22 22 20.3584 22 18.3333V3.66667C22 1.64162 20.3584 0 18.3333 0H3.66667ZM2 4C2 2.89543 2.89543 2 4 2H18C19.1046 2 20 2.89543 20 4V18C20 19.1046 19.1046 20 18 20H4C2.89543 20 2 19.1046 2 18V4Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>