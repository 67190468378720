<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2.5H18C18.8284 2.5 19.5 3.17157 19.5 4V18C19.5 18.8284 18.8284 19.5 18 19.5H4C3.17157 19.5 2.5 18.8284 2.5 18V4C2.5 3.17157 3.17157 2.5 4 2.5ZM12.9458 14.8125C13.372 15.0171 13.8521 15.1193 14.3862 15.1193C14.9714 15.1193 15.4899 14.9929 15.9416 14.7401C16.3961 14.4844 16.7512 14.135 17.0069 13.6918C17.2654 13.2486 17.3933 12.7443 17.3904 12.179C17.3933 11.6136 17.274 11.1136 17.0325 10.679C16.7939 10.2415 16.4657 9.89632 16.0481 9.64347C15.6333 9.39063 15.1603 9.26421 14.6291 9.26421C14.3052 9.26421 14.0012 9.31961 13.7172 9.43041C13.4359 9.5412 13.2101 9.67899 13.0396 9.84376H12.9885L13.2654 7.402H16.9686V6.27274H12.1703L11.6802 10.6705L12.8862 10.8494C13.051 10.7017 13.2669 10.5824 13.5339 10.4915C13.801 10.4006 14.0666 10.3551 14.3308 10.3551C14.6774 10.358 14.9842 10.4389 15.2512 10.598C15.5211 10.7571 15.7328 10.9759 15.8862 11.2543C16.0396 11.5327 16.1163 11.8494 16.1163 12.2046C16.1163 12.554 16.041 12.8651 15.8904 13.1378C15.7427 13.4105 15.5382 13.625 15.2768 13.7813C15.0183 13.9347 14.7214 14.0114 14.3862 14.0114C13.9657 14.0114 13.6049 13.8906 13.3038 13.6492C13.0027 13.4077 12.8365 13.0994 12.8052 12.7244H11.5268C11.5439 13.1875 11.6788 13.5994 11.9316 13.9602C12.1845 14.321 12.5226 14.6051 12.9458 14.8125ZM4.85139 15V6.27274H6.16815V13.8665H10.1227V15H4.85139Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.66667 0C1.64162 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.64162 22 3.66667 22H18.3333C20.3584 22 22 20.3584 22 18.3333V3.66667C22 1.64162 20.3584 0 18.3333 0H3.66667ZM2 4C2 2.89543 2.89543 2 4 2H18C19.1046 2 20 2.89543 20 4V18C20 19.1046 19.1046 20 18 20H4C2.89543 20 2 19.1046 2 18V4Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>