<template>
  <div>
    <div class="claim-offer-form-wrapper">
      <form action="">
        <div class="form-group">
          <label class="form-label">Full name</label>
          <el-input placeholder="Ex:John Smith" v-model="name" />
        </div>

        <div class="form-group">
          <label class="form-label">Phone number</label>
          <el-input
            placeholder="(555) 555-5555"
            type="tel"
            v-model="phone"
            :disabled="!isEmbeddedInCrm"
            inputmode="tel"
            oninput="this.value = this.value.replace(/[^\d+]+/g, '').replace(/(\..*)\./g, '$1')"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Email</label>
          <el-input placeholder="Ex: example@gmail.com" type="email" v-model="email" />
        </div>

        <div v-if="requireAddress" class="form-group">
          <label class="form-label">Address</label>
          <autocomplete v-model="address" />
        </div>

        <transition name="fade-faster">
          <div v-if="errorMessage" class="form-group">
            <div class="text-error">
              {{ errorMessage }}
            </div>
          </div>
        </transition>

        <!-- <div class="form-group">
          <el-checkbox class="terms-checkbox gray-text" v-model="terms">
            I agree to receive SMS and email notifications about my appointment
            from {{ accountName }}. Messaging and data rates may apply. Reply
            STOP to opt-out at any time. Our
            <router-link :to="'/terms?shop='+ this.$route.query.shop" target="_blank">
              <span class="text-primary">Terms & Conditions</span>
            </router-link>.
          </el-checkbox>
        </div> -->
      </form>
    </div>

    <div class="sticked-to-bottom-button-wrapper">
      <div class="content-container">
        <button
          type="button"
          class="primary-btn full-width submit-contact-info"
          :disabled="nextButtonDisabled"
          @click.prevent="verifyAndSubmit"
        >
          <span>Next</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import AppointmentsService from '@/services/AppointmentsService';
import AutocompleteInput from '../global/AutocompleteInput.vue';

export default {
  name: 'CustomerInfoForm',
  props: {
    requireAddress: {
      type: Boolean,
      required: false,
    },
    accountSlug: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    customerPhone: {
      type: String,
      default: '',
    },
  },
  components: {
    autocomplete: AutocompleteInput,
  },
  mounted() {
    this.$posthog.capture('addContactDetails_*_pageview');
  },
  data() {
    return {
      name: '',
      email: '',
      phone: this.customerPhone,
      address: {
        formatted: '',
        place_id: '',
        address: '',
        zip: '',
        state: '',
        city: '',
      },

      // terms: false,
      errorMessage: '',
      loading: false,
    };
  },
  computed: {
    nextButtonDisabled() {
      return (
        !this.name ||
        !this.email ||
        (this.isEmbeddedInCrm && !this.phone) ||
        this.loading ||
        // !this.terms ||
        (this.requireAddress && !this.address.formatted)
      );
    },
    isEmbeddedInCrm() {
      return !!this.$route.query.crm_embed;
    },
  },
  methods: {
    analyticsTrackSubmitClick() {
      this.$posthog.capture('addPhoneNumber_click_nextButton');
    },
    async verifyAndSubmit() {
      this.errorMessage = '';

      const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
      const isEmailValid = this.email.match(emailRegex);

      if (!isEmailValid) {
        this.errorMessage = 'The e-mail you entered is invalid';
        return;
      }

      try {
        const { name, email, phone, address } = this.$data;

        this.loading = true;

        let recognisedVehicles = [];
        let recognisedCustomerSlug = null;

        if (email && phone) {
          const {
            data: { recognised_vehicles, customer_slug },
          } = await AppointmentsService.get.recognisedCustomerVehicles(this.accountSlug, email, phone);

          recognisedVehicles = recognised_vehicles.map((vehicle) => ({
            ...vehicle,
            slug: _.uniqueId(),
          }));

          recognisedCustomerSlug = customer_slug;
        }

        this.$posthog.identify(email, {
          email,
          name,
          accountSlug: this.accountSlug,
        });
        this.loading = false;
        this.$emit('next-step', {
          name,
          email,
          phone,
          address,
          recognisedVehicles,
          recognisedCustomerSlug,
        });
        this.$emit('start-abandonment-timer', { name, phone, email });
      } catch (e) {
        this.loading = false;
        this.$posthog.capture('addPhoneNumber_*_requestFailed', {
          errorMessage: this.errorMessage,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.error-message {
  color: #c52020;
  font-size: 13px;
  margin-top: 5px;
}

.terms-checkbox {
  .el-checkbox__label {
    white-space: break-spaces;
    font-size: 13px;
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;
  }

  .el-checkbox__inner::after {
    left: 7px;
    height: 12px;
    width: 4px;
  }
}
</style>
