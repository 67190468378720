<template>
  <div class="appointment-booked-screen">
    <lottie-player
      src="/assets/lottie-confetti.json"
      class="lottie-confetti"
      background="transparent"
      speed="1"
      autoplay
    />
    <div class="appointment-details">
      <div class="appointment-time-wrapper">
        <p class="text-header-4">
          We messaged you all the details and are excited to see you on
          <strong>{{ appointmentTimeFormatted }}</strong>
        </p>
      </div>
      <div class="appointment-icon-service-wrapper">
        <div class="appointment-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="wrench">
              <path
                id="Icon Color"
                d="M15.835 6.72897C16.0089 6.5551 16.0865 6.30705 16.0427 6.06509C15.9989 5.82314 15.8393 5.61801 15.6156 5.5161C13.4966 4.55104 10.9104 4.9389 9.16524 6.68401C7.58354 8.26571 7.27557 10.6197 7.87229 12.5599L5.53033 14.9019C5.23744 15.1948 5.23744 15.6697 5.53033 15.9626C5.82322 16.2555 6.2981 16.2555 6.59099 15.9626L9.26659 13.287C9.47868 13.0749 9.54401 12.7568 9.43269 12.4783C8.79977 10.8948 9.02274 8.94783 10.2259 7.74467C11.2238 6.74681 12.5953 6.34827 13.8916 6.55105L12.0561 8.38656C11.3727 9.06998 11.3727 10.178 12.0561 10.8614L13.1197 11.925C13.8031 12.6084 14.9112 12.6084 15.5946 11.925L17.43 10.0896C17.6327 11.3859 17.2341 12.7573 16.2363 13.7551C15.058 14.9334 13.0995 15.1338 11.5103 14.5235C11.2337 14.4172 10.9206 14.4838 10.7111 14.6933L8.01639 17.388C7.72349 17.6808 7.72349 18.1557 8.01639 18.4486C8.30928 18.7415 8.78415 18.7415 9.07705 18.4486L11.4424 16.0832C13.3588 16.6516 15.724 16.3887 17.297 14.8157C19.042 13.0707 19.4299 10.4846 18.465 8.36569C18.3631 8.14191 18.158 7.98229 17.916 7.9385C17.674 7.89471 17.426 7.97231 17.2521 8.14618L14.5339 10.8644C14.4363 10.962 14.278 10.962 14.1804 10.8644L13.1168 9.80077C13.0192 9.70314 13.0192 9.54485 13.1168 9.44722L15.835 6.72897Z"
                fill="#2D3139"
              />
            </g>
          </svg>
        </div>
        <p class="text-header-4 appointment-service-detail">
          {{ selectedServiceDescription }}
        </p>
      </div>
      <hr class="divider" />
      <div v-if="appointmentType === 'drop_off'" class="appointment-type-detail">
        <div class="text-header-4">
          <strong>Drop-off appointment</strong>
        </div>
        <p class="text-header-4" v-if="dropOffPolicy">
          {{ dropOffPolicy }}
        </p>
        <p class="text-header-4" v-else>
          Head to the shop and leave your vehicle with us. If you have questions, just ask our team.
        </p>
      </div>
      <div v-else class="appointment-type-detail">
        <div class="text-header-4">
          <strong>Waiting appointment</strong>
        </div>
        <p class="text-header-4">Head to the shop and relax in our waiting room while we take care of your vehicle.</p>
      </div>
    </div>
    <fixed-shop-map :selectedAccountLocation="selectedAccountLocation" />
    <div class="box-actions sticked-to-bottom-button-wrapper">
      <button @click="openGeoLink" class="secondary-btn outline half-width">Get directions</button>
      <button id="calendar-button" class="primary-btn half-width">Add to calendar</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import ShopServicesService from '@/services/ShopServicesService';
import { atcb_init, atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import FixedShopMap from './FixedShopMap';

export default {
  name: 'AppointmentBookedScreen',
  components: {
    FixedShopMap,
  },
  props: {
    accountSlug: {
      type: String,
      required: true,
    },
    selectedAccountLocation: {
      type: Object,
      required: false,
    },
    dropOffPolicy: {
      type: String,
      required: false,
      default: '',
    },
    appointmentTime: {
      type: String,
      default: '',
    },
    appointmentType: {
      type: String,
      required: false,
      default: 'waiting',
    },
    selectedServices: {
      type: Array,
      required: false,
    },
    selectedServiceAdditionalNotes: {
      type: String,
      required: false,
    },
    diagnosticPrice: {
      type: Object,
      required: true,
    },
    requestedServiceDescription: {
      type: String,
      required: false,
    },
    deferredJobs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      shopInfo: {},
      eventData: {},
    };
  },
  methods: {
    async openGeoLink() {
      const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
      const isWindows = /windows/i.test(window.navigator.userAgent);

      try {
        // open maps app
        if (isIos) {
          // redirect user on parent iframe to google
          window.top.location.href = `maps://maps.google.com/maps?daddr=${this.shopInfo.lat},${this.shopInfo.lng}&amp;ll=`;
        } else if (isWindows) {
          // Just open google maps in browser
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${this.shopInfo.lat},${this.shopInfo.lng}`,
            '_blank',
          );
        } else {
          window.top.location.href = `maps://maps.google.com/maps?daddr=${this.shopInfo.lat},${this.shopInfo.lng}&amp;ll=`;
        }
      } catch (e) {
        alert(e.message);
      }
    },
    sendAnalyticsData() {
      const reportingData = {
        event: 'appointmentBooked',
        accountSlug: this.accountSlug,
        appointmentService:
          this.selectedServices && this.selectedServices.length
            ? this.selectedServices.map((service) => service.service_name).join(', ')
            : 'Diagnostic',
      };
      window.parent.postMessage(
        {
          name: 'reporting.dataLayerEvent',
          payload: reportingData,
        },
        '*',
      );
    },
  },
  computed: {
    appointmentTimeFormatted() {
      return moment(this.appointmentTime).format('MMMM Do [at] h:mm a');
    },
    selectedServiceDescription() {
      let servicesSelected = this.selectedServices.map((s) => s.service_name).join(', ');

      let notesToBeShown = this.selectedServiceAdditionalNotes ? ' - ' + this.selectedServiceAdditionalNotes : '';

      let description =
        this.selectedServices && this.selectedServices.length
          ? servicesSelected + notesToBeShown
          : this.requestedServiceDescription;

      if (this.deferredJobs.length) {
        const deferredJobsDescription = this.deferredJobs.map(({ name }) => name).join(', ');
        description += `, ${deferredJobsDescription}`;
      }

      return description;
    },
  },
  async mounted() {
    this.shopInfo = (await ShopServicesService.get.shopInfo(this.accountSlug)).data.data;

    this.eventData = {
      name: 'Appointment At ' + this.shopInfo.name,
      description: this.selectedServiceDescription,
      startDate: moment(this.appointmentTime).format('YYYY-MM-DDTHH:mm:ss'),
      timeZone: this.shopInfo.timezone,
      endDate: moment(this.appointmentTime).add(1, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      options: ['Apple', 'Google', 'Microsoft365', 'MicrosoftTeams', 'iCal'],
      trigger: 'click',
      listStyle: 'list',
      iCalFileName: 'download_reminder',
      hideBackground: true,
    };
    this.$nextTick(() => {
      atcb_init();
    });

    const button = document.querySelector('#calendar-button');
    button.addEventListener('click', () => atcb_action(this.eventData, button));

    this.sendAnalyticsData();
  },
};
</script>
<style lang="css" scoped>
.lottie-confetti {
  width: 100%;
  height: 100%;
  /* transform: scale(1.5) translateY(-20%); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.lottie-confetti {
  pointer-events: none;
}
</style>
