<template>
  <div class="otp-enter-code-wrapper">
    <div class="icon-wrapper">
      <unread-message-icon v-if="this.accountOtpSetting == 'sms' || !this.accountOtpSetting" />
      <unread-message-icon-colored v-else />
    </div>

    <div v-if="this.accountOtpSetting == 'sms' || !this.accountOtpSetting">
      <div class="text-header-1 mb-8px">We texted you a code</div>
      <div class="text-body-4 mb-16px">
        We sent a code to <strong>{{ customerPhone }}</strong
        >. Please enter your 5-digit verification code below.
      </div>
    </div>
    <div v-else>
      <div class="text-header-1 mb-8px">We emailed your code to {{ customerEmail }}</div>
      <div class="text-body-4 mb-16px">Please enter your 5-digit verification code below.</div>
    </div>

    <transition name="fade-faster">
      <div class="mb-16px" v-if="success">
        <el-alert class="text-left" effect="dark" :title="success" type="success" />
      </div>
    </transition>

    <transition name="fade-faster">
      <div class="mb-16px" v-if="error">
        <el-alert class="text-left" effect="dark" :title="error" type="error" />
      </div>
    </transition>

    <div class="verify-otp-wrapper">
      <simple-otp-input
        input-classes="otp-input"
        :length="5"
        @change="handleOnChange"
        @complete="handleOnComplete"
        type="number"
      />

      <button class="resend-btn" :disabled="resendOTPLoading" @click.prevent="resendOTP">
        <span v-if="resendOTPLoading" class="btn-loader"></span>
        <span :class="{ 'opacity-0': resendOTPLoading }">Resend code</span>
      </button>
    </div>

    <transition name="fade-faster">
      <sticked-to-bottom-button v-if="otpCodeDigitsFilled" :loading="verifyLoading" @btn-click="verifyOTP" />
    </transition>
  </div>
</template>

<script>
import UnreadMessageIcon from './UnreadMessageIcon';
import UnreadMessageIconColored from './UnreadMessageIconColored';
import StickedToBottomButton from '@/components/global/StickedToBottomButton';
import SimpleOtpInput from 'vue-simple-otp-input';

import OTPService from '@/services/OTPService';

export default {
  name: 'VerifyCustomerPhone',
  components: {
    UnreadMessageIcon,
    UnreadMessageIconColored,
    StickedToBottomButton,
    SimpleOtpInput,
  },
  props: {
    accountSlug: {
      type: String,
      required: true,
    },
    customerPhone: {
      type: String,
      required: true,
    },
    customerEmail: {
      type: String,
      required: false,
    },
    accountOtpSetting: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      success: '',
      error: '',
      otpCodeDigits: Array(5).fill(''),
      resendOTPLoading: false,
      verifyLoading: false,
      dataFromPaste: undefined,
      keysAllowed: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      maxCodeInputLength: 1,
    };
  },
  computed: {
    otpCodeDigitsFilled() {
      return this.otpCodeDigits.length === 5 && this.otpCodeDigits.every((code) => !!code && code !== '');
    },
  },
  watch: {
    otpCodeDigitsFilled(codeFilled) {
      if (codeFilled) this.verifyOTP();
    },
  },
  methods: {
    handleOnChange(data) {},
    handleOnComplete(otpCode) {
      this.verifyOTP(otpCode);
      this.validatePastedData(this.dataFromPaste, event);
    },
    validatePastedData(data, event) {
      if (data) {
        for (const num of data) {
          if (!this.keysAllowed.includes(num)) event.preventDefault();
        }
      }
    },
    async resendOTP() {
      this.success = '';
      this.error = '';

      try {
        this.resendOTPLoading = true;
        let response, data;
        response =
          this.accountOtpSetting === 'sms'
            ? await OTPService.post.resendOTP(this.customerPhone)
            : await OTPService.post.resendEmailOTP(this.accountSlug, this.customerPhone);
        data = response.data;
        this.$posthog.capture('addPhoneNumber_*_otpResent');
        this.success = data.message;
        this.otpCodeDigits = Array(5).fill('');
        this.resendOTPLoading = false;
      } catch (error) {
        this.error = error.response.data.message;
        this.$posthog.capture('addPhoneNumber_*_otpResentFailed', {
          errorMessage: error.response.data.message,
        });
        this.resendOTPLoading = false;
      }
    },
    async verifyOTP(otpCode) {
      this.success = '';
      this.error = '';

      let response, data;
      try {
        this.verifyLoading = true;

        if (this.accountOtpSetting === 'sms') {
          response = await OTPService.post.verifyOTP(this.accountSlug, this.customerPhone, otpCode);
        } else {
          response = await OTPService.post.verifyEmailOTP(this.accountSlug, this.customerPhone, otpCode);
        }
        data = response.data;

        this.$posthog.capture('addPhoneNumber_*_otpVerified');

        this.verifyLoading = false;
        this.$emit('customer-phone-confirmed', {
          recognisedCustomer: data?.recognised_customer,
          recognisedVehicles: data?.recognised_vehicles?.map((vehicle) => ({
            ...vehicle,
            slug: _.uniqueId(),
          })),
        });
      } catch (error) {
        this.verifyLoading = false;
        this.error = error?.response?.data?.message;
      }
    },
  },
};
</script>

<style lang="scss">
.hidden-otp-input {
  display: none;
}
.simple-otp-input {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;

  .input-box-wrapper {
    width: 44px;
    height: 44px;

    input {
      border: 1px solid #e3e5e8;
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 8px;
      outline: none;
      font-size: 14px;

      &:focus {
        border-color: #377aff;
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
  .otp-input {
    width: 44px;
    height: 44px;
    margin: 0 12px;
    outline: none;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
    &:focus {
      border-color: #377aff;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
