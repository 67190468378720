<template>
  <div class="deferred-job-card" :class="{ selected: selected }">
    <!-- <div class="job-header"> -->
    <div class="text-body-4">{{ deferredJob.name }}</div>
    <div v-if="deferredJob.created_date" class="text-body-5">
      Recommended {{ deferredJob.created_date }}
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "DeferredServiceCard",
  props: {
    deferredJob: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>