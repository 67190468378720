<template>
  <div class="day" :class="{ moving: weekMoving }">
    <div
      class="day-label"
      :class="{
        'is-selected': isDaySelected,
        'is-disabled': isDayInThePast || isDayUnavailable,
      }"
    >
      {{ day.label }}
    </div>
    <div
      class="day-numeric"
      :class="{
        'is-disabled': isDayInThePast || isDayUnavailable,
        'is-selected': isDaySelected,
      }"
    >
      {{ day.numeric }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarDay",
  props: {
    day: {
      type: Object,
      required: true,
    },
    weekMoving: {
      type: [Boolean, String],
      default: false,
    },
    isDayInThePast: {
      type: Boolean,
      required: true,
    },
    isDayUnavailable: {
      type: Boolean,
      required: true,
    },
    isDaySelected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
</style>