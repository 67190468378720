import apiClient from "@/client";

export default {
  post: {
    sendOTP: (accountSlug, phone) => {
      return apiClient.post(`/public/otp/send`, {
        phone,
        account_slug: accountSlug,
      });
    },
    resendOTP: (phone) => {
      return apiClient.post(`/public/otp/resend`, {
        phone,
      });
    },
    verifyOTP: (accountSlug, phone, otp) => {
      return apiClient.post(`/public/otp/verify`, {
        account_slug: accountSlug,
        phone,
        otp,
      });
    },
    // email otp
    sendEmailOTP: (accountSlug, phone) => {
      return apiClient.post(`/public/otp/email/send`, {
        phone,
        account_slug: accountSlug,
      });
    },
    resendEmailOTP: (accountSlug, phone) => {
      return apiClient.post(`/public/otp/email/resend`, {
        phone,
        account_slug: accountSlug,
      });
    },
    verifyEmailOTP: (accountSlug, phone, otp) => {
      return apiClient.post(`/public/otp/email/verify`, {
        account_slug: accountSlug,
        phone,
        otp,
      });
    },
  },
};
