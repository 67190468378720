import apiClient from '@/client';

export default {
  get: {
    years: () => {
      return apiClient.get('/public/vehicles/years');
    },
    makes: () => {
      return apiClient.get('/public/vehicles/makes');
    },
    accountScopedMakes: slug => {
      return apiClient.get(`/public/vehicles/${slug}/makes`);
    },
    models: makeId => {
      return apiClient.get(`/public/vehicles/models?make_id=${makeId}`);
    }
  }
};